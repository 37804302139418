import { useTheme } from "@mediaspace/shared/styled";
import { useMediaQuery } from "@mediaspace/shared/utils";
import { Button, IconButton } from "@kaltura/ds-react-components";
import { MouseEventHandler, ReactElement, useMemo } from "react";
import styled from "@emotion/styled";

export interface ChangingButtonProps {
    label: string;
    icon: ReactElement;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    href?: string;
    target?: string;
    disabled?: boolean;
    selected?: boolean;
}

// this is an ugly hack to allow using the buttons' "target" prop
export const StyledButton = styled(Button)(
    ({ theme, target }: { theme?: any; target?: string; }) => ({
      [`&.upload-changing-button-selected`]: {
        backgroundColor: theme.kaltura.palette.tone6
      }
    })
);

export const StyledIconButton = styled(IconButton)(
    ({ theme, target }: { theme?: any; target?: string; }) => ({
      [`&.upload-changing-button-selected`]: {
        backgroundColor: theme.kaltura.palette.tone6
      }
    })
);

/**
 * a wrapper for a Button that switches to an IconButton on small screens
 * @param props
 * @constructor
 */
export function ChangingButton(props: ChangingButtonProps) {
    const theme = useTheme();
    const isXsScreen = useMediaQuery(
        theme.breakpoints.down(theme.breakpoints.values.sm)
    );

    const ButtonComponent = useMemo(
        () => (isXsScreen ? StyledIconButton : StyledButton),
        [isXsScreen]
    );


    return (
        <ButtonComponent
            variant={"borderless"}
            startIcon={!isXsScreen && props.icon}
            onClick={props.onClick}
            href={props.href}
            aria-label={isXsScreen ? props.label : undefined}
            target={props.target}
            disabled={props.disabled}
            classes={props.selected ? {root: "upload-changing-button-selected"} : {}}
        >
            {!isXsScreen && props.label}
            {isXsScreen && props.icon}
        </ButtonComponent>
    );
}

export default ChangingButton;
