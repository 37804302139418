import styled from "@emotion/styled";
import { KmsTypePublishCategory } from "@mediaspace/shared/types";
import { Typography } from "@kaltura/ds-react-components";
import { CategoryLinks } from "./CategoryLinks";

interface CategoryLink extends KmsTypePublishCategory {
    url?: string;
}
interface TitledCategoryLinksProps {
    className?: string;
    categories: CategoryLink[];
    title: string;
    color: string;
}

const StyledCategories = styled("div")(({ theme }: { theme?: any }) => ({
    marginBottom: theme.spacing(1),
    display: "flex",
}));

const StyledTitle = styled(Typography)(({ theme }: { theme?: any }) => ({
    marginRight: theme.spacing(0.5),
    textWrap: "nowrap",
    marginTop: theme.spacing(0.5),
}));

/**
 * titled category links - category links with a title (pending:/rejected:)
 */
export function TitledCategoryLinks({ className = "", categories, title, color }: TitledCategoryLinksProps) {
    return (
        <>
            {categories.length > 0 && (
                <StyledCategories className={className}>
                    <StyledTitle variant="body2Highlight" color={color}>
                        {title}
                    </StyledTitle>
                    <CategoryLinks categories={categories} />
                </StyledCategories>
            )}
        </>
    );
}
