import styled from "@emotion/styled";
import {Button, buttonClasses} from "@kaltura/ds-react-components";
import {KafActionProps} from "../KafAction";

const StyledButton = styled(Button)<{ component: "a" }>(({theme}) => ({
    [`&.${buttonClasses.root}`]: {
        color: theme.kaltura.palette.tone1
    }
}));

/**
 * KafButtonLinkAction component - renders a link that looks like a button
 */
const KafButtonLinkAction = (props: KafActionProps) => {

    const {label, href, icon} = props;
    const {component: IconComponent} = icon;

    return (
        <StyledButton
            variant={"borderless"}
            component={"a"}
            href={href}
            startIcon={<IconComponent />}
        >
            {label}
        </StyledButton>
    )
}

export default KafButtonLinkAction;
