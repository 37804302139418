import styled from "@emotion/styled";
import {
    KmsTypePublishUserCategories,
    KmsTypePublishCategories,
    KmsTypePublishCategory,
} from "@mediaspace/shared/types";
import { translate } from "@mediaspace/shared/utils";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Checkbox, Tooltip } from "@kaltura/ds-react-components";
import { CategoryIcon } from "../category-icon/CategoryIcon";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { channelLabel } from "../../../../../../utils";

export interface ChannelsTabFormProps {
    channels: KmsTypePublishCategories;
    userCategories: KmsTypePublishUserCategories;
    channelResults?: KmsTypePublishCategory[];
    onCategoryChange: (categoryId: number, checked: boolean) => void;
}

const StyledFormLabel = styled(FormLabel)<{ component?: "h3" }>(({ theme, component }) => ({
    display: "block",
    marginTop: 0,
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
    color: theme.kaltura.palette.tone2,
    fontSize: 12,
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
    svg: {
        width: 16,
        height: 16,
        marginRight: theme.spacing(0.5),
        position: "relative",
        top: 3,
    },
}));

/**
 * publish modal channels tab Form (checkboxes)
 */
export function ChannelsTabForm(props: ChannelsTabFormProps) {
    const { channels, userCategories, onCategoryChange, channelResults = [] } = props;
    const { myChannels = [], moreChannels = [] } = userCategories;
    const { published = [], pending = [] } = channels;

    const sendButtonAnalytics = useButtonAnalytics();

    const isChecked = (channelId: number) => {
        return !!(
            published.find((channel) => channel.id === channelId) || pending.find((channel) => channel.id === channelId)
        );
    };

    const handleCategoryChange = (id: number, checked: boolean) => {
        checked &&
            sendButtonAnalytics(
                "Publish - Set publish settings modal box - Channel checked",
                ButtonClickAnalyticsType.TOGGLE
            );
        !checked &&
            sendButtonAnalytics(
                "Publish - Set publish settings modal box - Channel unchecked",
                ButtonClickAnalyticsType.TOGGLE
            );

        onCategoryChange(id, checked);
    };

    return (
        <>
            {myChannels.length > 0 && (
                <>
                    <StyledFormLabel>{translate("channels i manage")}</StyledFormLabel>
                    <StyledFormGroup className="kms-ds-publish-my-channels">
                        {myChannels.map((channel) => (
                            <StyledFormControlLabel
                                key={channel.id}
                                control={
                                    <StyledCheckbox
                                        value={channel.id}
                                        disabled={false}
                                        checked={isChecked(channel.id)}
                                        onChange={(event, checked) => handleCategoryChange(channel.id, checked)}
                                    />
                                }
                                label={
                                    <StyledLabel variant="body2Highlight">
                                        <CategoryIcon category={channel} />
                                        <Tooltip title={channelLabel(channel)}>
                                            <span>{channel.name}</span>
                                        </Tooltip>
                                    </StyledLabel>
                                }
                            />
                        ))}
                    </StyledFormGroup>
                </>
            )}

            {moreChannels.length > 0 && (
                <>
                    <StyledFormLabel>{translate("more channels")}</StyledFormLabel>
                    <StyledFormGroup className="kms-ds-publish-more-channels">
                        {moreChannels.map((channel) => (
                            <StyledFormControlLabel
                                key={channel.id}
                                control={
                                    <StyledCheckbox
                                        value={channel.id}
                                        disabled={false}
                                        checked={isChecked(channel.id)}
                                        onChange={(event, checked) => handleCategoryChange(channel.id, checked)}
                                    />
                                }
                                label={
                                    <StyledLabel variant="body2Highlight">
                                        <CategoryIcon category={channel} />
                                        <Tooltip title={channelLabel(channel)}>
                                            <span>{channel.name}</span>
                                        </Tooltip>
                                    </StyledLabel>
                                }
                            />
                        ))}
                    </StyledFormGroup>
                </>
            )}

            {channelResults.length > 0 && (
                <>
                    <StyledFormGroup className="kms-ds-publish-search-channels">
                        {channelResults.map((channel) => (
                            <StyledFormControlLabel
                                key={channel.id}
                                control={
                                    <StyledCheckbox
                                        value={channel.id}
                                        disabled={false}
                                        checked={isChecked(channel.id)}
                                        onChange={(event, checked) => handleCategoryChange(channel.id, checked)}
                                    />
                                }
                                label={
                                    <StyledLabel variant="body2Highlight">
                                        <CategoryIcon category={channel} />
                                        <Tooltip title={channelLabel(channel)}>
                                            <span>{channel.name}</span>
                                        </Tooltip>
                                    </StyledLabel>
                                }
                            />
                        ))}
                    </StyledFormGroup>
                </>
            )}
        </>
    );
}
