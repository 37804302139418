import { translate } from "@mediaspace/shared/utils";
import { Bulletlist24Icon } from "@kaltura/ds-react-icons";
import { SidebarContentsContainer, SidebarContentsContainerProps } from "./SidebarContentsContainer";
import { ConfigSidebarButtonDescriptor } from "@mediaspace/shared/data-kms-config/ConfigSidebarButton";
import { SidebarMenuButtonWithAnalytics, SidebarMenuButtonWithAnalyticsProps } from "@mediaspace/features/layout";

/*
 * Agenda button descriptor for DS sidebar
 */
export const SidebarButton: ConfigSidebarButtonDescriptor<SidebarMenuButtonWithAnalyticsProps, SidebarContentsContainerProps> = {
    id: "channeltv",
    buttonComponent: SidebarMenuButtonWithAnalytics,
    buttonProps: { title: translate("Agenda"), icon: <Bulletlist24Icon />, analyticsText: "TV guide" },
    contentsComponent: SidebarContentsContainer,
};
