import { Fragment, useMemo } from "react";
import { Box, Skeleton } from "@mediaspace/shared/styled";
import { WebcastEntry } from "@mediaspace/shared/types";
import styled from "@emotion/styled";
import { convertToScheduledSessions } from "@mediaspace/event-platform/event-list";
import { useConfig } from "@mediaspace/shared/data-kms-config";
import { Typography } from "@kaltura/ds-react-components";
import { SidebarSessionItem } from "./SidebarSessionItem";
import { SidebarSessionBreak } from "./SidebarSessionBreak";
import { SidebarContentsContainerProps } from "./SidebarContentsContainer";

/*
 * Contents of the channel TV agenda sidebar
 */

export interface SidebarContentsProps extends SidebarContentsContainerProps {
    fetchEntries: () => { entries: WebcastEntry[] };
}

export const SidebarContents = ({
    fetchEntries,
    channelId,
    currentEntryId,
    minBreak,
    maxBreak,
}: SidebarContentsProps) => {
    const { entries } = fetchEntries();

    const {
        application: {
            dateFormats: { longDateNoYear },
        },
    } = useConfig();

    const sessions = useMemo(() => convertToScheduledSessions(entries, longDateNoYear), [entries, longDateNoYear]);

    return (
        <StyledContainer>
            {sessions.map((session, index) => {
                const prevSession = sessions[index - 1];

                return (
                    <Fragment key={session.id}>
                        {prevSession && (
                            <SidebarSessionBreak
                                start={prevSession.schedulingData.end.timestamp}
                                end={session.schedulingData.start.timestamp}
                                min={minBreak}
                                max={maxBreak}
                            />
                        )}

                        {prevSession?.schedulingData.label !== session.schedulingData.label && (
                            <Typography variant={"body2Highlight"}>{session.schedulingData.label}</Typography>
                        )}

                        <SidebarSessionItem
                            channelId={channelId}
                            session={session}
                            isActive={session.id === currentEntryId}
                        />
                    </Fragment>
                );
            })}
        </StyledContainer>
    );
};

const StyledContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
}));

export const SidebarContentsSkeleton = () => (
    <StyledContainer>
        <StyledSkeleton variant={"rectangular"} width={200} height={17} />
        <StyledSkeleton variant={"rectangular"} width={"100%"} height={130} />
        <StyledSkeleton variant={"rectangular"} width={"100%"} height={130} />
        <StyledSkeleton variant={"rectangular"} width={"100%"} height={130} />
    </StyledContainer>
);

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness1,
}));
