import { useMemo, useCallback } from "react";
import {
    KmsTypePublishUserCategories,
    KmsTypePublishCategories,
    KmsTypePublishCategory,
} from "@mediaspace/shared/types";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { GalleryCheckbox } from "./GalleryCheckbox";

export interface GalleriesTabFormProps {
    galleries: KmsTypePublishCategories;
    userCategories: KmsTypePublishUserCategories;
    galleryResults?: KmsTypePublishCategory[];
    onCategoryChange: (categoryId: number, checked: boolean) => void;
}

/**
 * publish modal Galleries tab Form (checkboxes)
 */
export function GalleriesTabForm(props: GalleriesTabFormProps) {
    const { galleries, userCategories, onCategoryChange, galleryResults = [] } = props;
    const { galleries: userGalleries } = userCategories;
    const { published = [], pending = [] } = galleries;

    const galleryLocations = userGalleries || galleryResults;

    const isChecked = useCallback(
        (galleryId: number) => {
            return !!(
                published.find((gallery) => gallery.id === galleryId) ||
                pending.find((gallery) => gallery.id === galleryId)
            );
        },
        [published, pending]
    );

    const sendButtonAnalytics = useButtonAnalytics();

    const handleCategoryChange = useCallback(
        (id: number, checked: boolean) => {
            checked &&
                sendButtonAnalytics(
                    "Publish - Set publish settings modal box - Gallery checked",
                    ButtonClickAnalyticsType.TOGGLE
                );
            !checked &&
                sendButtonAnalytics(
                    "Publish - Set publish settings modal box - Gallery unchecked",
                    ButtonClickAnalyticsType.TOGGLE
                );

            onCategoryChange(id, checked);
        },
        [onCategoryChange]
    );

    // deduce root category
    const rootGallery = useMemo(() => {
        const initialValue = galleryLocations[0]?.fullIds.split(">");
        return galleryLocations.reduce((accumulator, currentValue) => {
            const idsArr = currentValue.fullIds.split(">");
            return idsArr.filter((x) => accumulator.includes(x));
        }, initialValue);
    }, [galleryLocations]);

    // build gallery tree
    const calculateTree = (galleryLocations, rootGallery) => {
        const galleriesTree = { gallery: null, items: {} };

        galleryLocations.forEach((gallery) => {
            // get ids, from root gallery onward
            const idArray = gallery.fullIds.split(">").filter((x) => !rootGallery.includes(x));
            const nameArray = gallery.fullName.split(">");
            const delta = nameArray.length - idArray.length;

            let currentGallery = galleriesTree;

            idArray.forEach((id, index) => {
                // current location name
                const name = nameArray[index + delta];

                // current location - default is disabled gallery, until replaced by real one
                currentGallery["items"][id] = currentGallery["items"][id] ?? {
                    items: {},
                    gallery: { name: name, id: id },
                    disabled: true,
                };

                // leaf
                if (index === idArray.length - 1) {
                    currentGallery["items"][id].gallery = gallery;
                    currentGallery["items"][id].disabled = false;
                }

                // next location
                currentGallery = currentGallery["items"][id];
            });
        });
        return galleriesTree;
    };

    const galleriesTree = useMemo(() => calculateTree(galleryLocations, rootGallery), [galleryLocations, rootGallery]);

    return (
        <GalleryCheckbox
            {...props}
            depth={1}
            isChecked={isChecked}
            onCategoryChange={handleCategoryChange}
            {...galleriesTree}
        ></GalleryCheckbox>
    );
}
