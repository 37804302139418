import React from "react";
import styled from "@emotion/styled";
import { CenteredContentContainer, StyledTitle, VerticallyCenteredContainer, Wrapper } from "./StyledComponents";
import AnimatedAiIllustration from "@mediaspace/shared/ui/animated-ai-illustration/AnimatedAiIllustration";
import { translate } from "@mediaspace/shared/utils";
import TextSlider from "@mediaspace/shared/ui/text-slider/TextSlider";
import { Typography } from "@kaltura/ds-react-components";

/**
 * Generate AI quiz loading state
 */
export const AiQuizSkeleton = () => {
    return (
        <Wrapper>
            <StyledTitle variant="h2" align={"center"} component={"h1"}>
                {translate("Create video quiz with AI")}
            </StyledTitle>
            <CenteredContentContainer>
                <VerticallyCenteredContainer>
                    <AnimatedAiIllustration />
                    <TextContainer>
                        <TextSlider>
                            <SpanTypography variant={"h5"} component="span">{translate("Doing our magic")}</SpanTypography>
                            <SpanTypography variant={"h5"} component={"span"}>{translate("Adding some glitter")}</SpanTypography>
                            <SpanTypography variant={"h5"} component={"span"}>{translate("Painting your vision")}</SpanTypography>
                            <SpanTypography variant={"h5"} component={"span"}>{translate("Creating innovative spells")}</SpanTypography>
                            <SpanTypography variant={"h5"} component={"span"}>{translate("Crafting wonders with skill")}</SpanTypography>
                        </TextSlider>
                    </TextContainer>
                </VerticallyCenteredContainer>
            </CenteredContentContainer>
        </Wrapper>
    );
};

const SpanTypography = styled(Typography)<{ component: "span" }>({});

const TextContainer = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(6),
}));
