import React, { useState } from "react";
import { PublishStatus } from "@mediaspace/shared/types";
import { Button, ModalActions, ModalTitle } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import { MediaCategoriesContainer } from "../../../media-categories/MediaCategoriesContainer";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { CategoryLinkAnalyticsContext } from "@mediaspace/shared/entry";
import { ModalProps, StyledModal, StyledModalContent } from "../StyledModal";

/**
 * Publish menu (set as-)Private selection modal
 */
export function PrivateModal(props: ModalProps) {
    const { onClose, onSubmit, entryIds, publishStatus } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const [processing, setProcessing] = useState<boolean>(false);

    const isUnlisted = publishStatus === PublishStatus.UNLISTED;
    const isPublished = publishStatus === PublishStatus.PUBLISHED;

    const entryId = entryIds.length === 1 ? entryIds[0] : null;

    const handleCancel = () => {
        isUnlisted &&
            sendButtonAnalytics(
                "Publish - Set unlisted to private modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        isPublished &&
            sendButtonAnalytics(
                "Publish - Set published to private modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        onClose?.();
    };

    const handleSubmit = () => {
        isUnlisted &&
            sendButtonAnalytics(
                "Publish - Set unlisted to private modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );
        isPublished &&
            sendButtonAnalytics(
                "Publish - Set published to private modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );

        setProcessing(true);
        onSubmit?.({ entryIds: entryIds, state: PublishStatus.PRIVATE });
    };

    const handleCategoryLinkAnalytics = () => {
        isUnlisted &&
            sendButtonAnalytics(
                "Publish - Set unlisted to private modal box - Link to category",
                ButtonClickAnalyticsType.LINK,
                entryId
            );
        isPublished &&
            sendButtonAnalytics(
                "Publish - Set published to private modal box - Link to category",
                ButtonClickAnalyticsType.LINK,
                entryId
            );
    };

    return (
        <StyledModal
            open={true}
            className="kms-ds-publish-modal-private"
            onMouseLeave={(event) => event.stopPropagation()}
        >
            <ModalTitle>{translate("Set media as private")}</ModalTitle>
            <StyledModalContent>
                {isUnlisted &&
                    translate(
                        "Are you sure you want to set this media as private? It will no longer be available for users in search results or with a link."
                    )}
                {isPublished && (
                    <>
                        {translate(
                            "Are you sure you want to set this media as private? It will be removed from all published locations in this application."
                        )}
                        <CategoryLinkAnalyticsContext.Provider
                            value={{
                                sendCategoryLinkAnalytics: handleCategoryLinkAnalytics,
                                sendCategoryBubbleAnalytics: null,
                            }}
                        >
                            <MediaCategoriesContainer entryId={entryId} />
                        </CategoryLinkAnalyticsContext.Provider>
                    </>
                )}
            </StyledModalContent>

            <ModalActions>
                <Button variant={"borderless"} onClick={handleCancel} disabled={processing}>
                    {translate("Cancel")}
                </Button>
                <Button onClick={handleSubmit} loading={processing} disabled={processing}>
                    {translate("Save")}
                </Button>
            </ModalActions>
        </StyledModal>
    );
}
