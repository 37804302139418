import styled from "@emotion/styled";
import { Alert } from "@kaltura/ds-react-components";
import { KmsTypePublishEntryCategories } from "@mediaspace/shared/types";
import { CategoryBubbles } from "../../../../../media-categories/category-bubbles/CategoryBubbles";
import { translate } from "@mediaspace/shared/utils";
import { addCategoryLabel } from "../../../../../utils";

export interface PublishedCategoriesProps {
	entryCategories: KmsTypePublishEntryCategories;
	entryId: string;
	onLocationsCount: (count: number) => void;
	onDelete: (categoryId: number) => void;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

const StyledPublishedCategories = styled("div")(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

/**
 * publish modal locations - published(+ pending + rejected) categories
 */
export function PublishedCategories({
	entryCategories,
	entryId,
	onLocationsCount,
	onDelete,
}: PublishedCategoriesProps) {
	const { channels = {}, galleries = {}, privateCategories } = entryCategories;

	// get the published categories
    const { published: publishedChannels = [] } = channels;
    const { published: publishedGalleries = [] } = galleries;
    const publishedLocations = addCategoryLabel(publishedChannels, publishedGalleries);

    // get the pending categories
    const { pending: pendingChannels = [] } = channels;
    const { pending: pendingGalleries = [] } = galleries;
    const pendingLocations = addCategoryLabel(pendingChannels, pendingGalleries);

    // get the rejected categories
    const { rejected: rejectedChannels = [] } = channels;
    const { rejected: rejectedGalleries = [] } = galleries;
    const rejectedLocations = addCategoryLabel(rejectedChannels, rejectedGalleries);

	const publishedCategories = [
		...publishedLocations,
		...pendingLocations,
		...rejectedLocations,
	];

	onLocationsCount(publishedCategories.length);

	return (
		<>
			{privateCategories && (
				<StyledAlert severity="warning">{translate("Media is published to a private location")}</StyledAlert>
			)}

			{publishedCategories.length > 0 && (
				<StyledPublishedCategories className="kms-ds-publish-media-locations-published">
					<CategoryBubbles categories={publishedCategories} entryId={entryId} onDelete={onDelete} />
				</StyledPublishedCategories>
			)}
		</>
	);
}
