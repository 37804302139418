import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { AutoComplete, autoCompleteClasses } from "@kaltura/ds-react-components";

/**
 * component for showing avatar image
 */
export const StyledAvatarImage = styled.img(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: theme.kaltura.shape.roundness1,
}), {label: 'StyledAvatarImage'});

/**
 * Component used in custom selected values renderer
 */
export const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness1,
    backgroundColor: theme.kaltura.palette.tone7,
    color: theme.kaltura.palette.tone1,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    lineHeight: 16,
    "&.MuiChip-root": {
        maxWidth: 220,
    },
    "& .MuiChip-avatar": {
        margin: 0,
    },
    "& .MuiChip-deleteIcon": {
        color: theme.kaltura.palette.tone1,
    }
}), {label: 'StyledChip'});

export const StyledLi = styled.li(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),

}));

export const StyledInitialsWrap = styled.div(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
}));

export const StyledAutocomplete = styled(AutoComplete)(({ theme }: { theme?: any }) => ({
    [`& .${autoCompleteClasses.input}`]: {
        paddingRight: "1.75rem"
    }
}));

