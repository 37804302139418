import styled from "@emotion/styled";
import {ModalContent, ModalActions, Typography} from "@kaltura/ds-react-components";

export const StyledModalContent = styled(ModalContent)(({ theme }: { theme?: any }) => ({
    width: theme.typography.pxToRem(584),
    textAlign: "center",
    borderBottom: `1px solid ${theme.kaltura.palette.tone4}`,
    padding: theme.spacing(3)
}));

export const StyledModalActions = styled(ModalActions)(({ theme }: { theme?: any }) => ({
    padding: theme.spacing(2)
}));

export const StyledTypography = styled(Typography)(({ theme }: { theme?: any }) => ({
    marginBottom: theme.spacing(3)
}));
