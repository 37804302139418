import styled from "@emotion/styled";
import { Info24Icon } from "@kaltura/ds-react-icons";
import { Tooltip } from "@kaltura/ds-react-components";
import { baseFormLabelClasses } from "./../../base-form-label/BaseFormLabel";
import { FormLabel, FormLabelProps } from "./../form-label/FormLabel";

export interface FormLabelWithDescriptionProps extends FormLabelProps {
    description?: string;
}


export const StyledFormLabelWithDescription = styled("div")(({ theme }: { theme?: any }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
    alignItems: "center"
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }: { theme?: any }) => ({
    [`& .${baseFormLabelClasses.label}`]: {
        paddingBottom: 0
    },
    [`&.${baseFormLabelClasses.root}`]: {
        marginBottom: 0
    }
}));

export function FormLabelWithDescription({description, className, ...rest}: FormLabelWithDescriptionProps) {

    return (
        <StyledFormLabelWithDescription className={className}>
            <StyledFormLabel {...rest} />
            {description && (
                <Tooltip title={description}><Info24Icon tabIndex={0} aria-hidden={false}/></Tooltip>
            )}
        </StyledFormLabelWithDescription>
    );
}

export default FormLabelWithDescription;
