import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { Skeleton } from "@mediaspace/shared/styled";
import { StyledModalContent } from "../../StyledModal";
import { Button, ModalActions } from "@kaltura/ds-react-components";
import { translate, noop } from "@mediaspace/shared/utils";

const StyledPublishModalSkeleton = styled("div")(({ theme }: { theme?: any }) => ({
    paddingTop: theme.spacing(3),
}));

const StyledSkeletonRow = styled("div")(({ theme }: { theme?: any }) => ({
    marginTop: theme.spacing(2),
}));

const StyledSkeleton = styled(Skeleton)(({ theme }: { theme?: any }) => ({
    display: "inline-block",
    borderRadius: theme.kaltura.shape.roundness1,
    marginRight: theme.spacing(2),
}));

const StyledHr = styled("hr")(({ theme }: { theme?: any }) => ({
    marginTop: theme.spacing(2),
}));

/**
 * publish modal skeleton
 */
export function PublishedModalSkeleton({ onClose }: { onClose: () => void }) {
    const theme = useTheme();

    return (
        <>
            <StyledModalContent>
                <StyledPublishModalSkeleton>
                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(3)} width={theme.spacing(9)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(3)} width={theme.spacing(9)} />
                    </StyledSkeletonRow>

                    <StyledHr />

                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(4)} width={"100%"} />
                    </StyledSkeletonRow>
                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"50%"} />
                    </StyledSkeletonRow>
                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"50%"} />
                    </StyledSkeletonRow>

                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"25%"} />
                    </StyledSkeletonRow>

                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"35%"} />
                    </StyledSkeletonRow>
                </StyledPublishModalSkeleton>
            </StyledModalContent>

            <ModalActions>
                <Button variant={"borderless"} onClick={onClose}>
                    {translate("Cancel")}
                </Button>
                <Button onClick={noop} disabled={true}>
                    {translate("Save")}
                </Button>
            </ModalActions>
        </>
    );
}
