import styled from "@emotion/styled";
import {Modal, ModalContent, ModalActions} from "@kaltura/ds-react-components";

export const StyledModal = styled(Modal)(({ theme }: { theme?: any }) => ({
    ['& .uploadConfirmationModal']: {
        width: theme?.typography.pxToRem(600),
        height: theme?.typography.pxToRem(236),
        [theme.breakpoints.down("sm")]: {
            width: theme?.typography.pxToRem(343),
        },
    },
    ['& .uploadBulkEditModal']: {
        width: theme?.typography.pxToRem(790),
        height: theme?.typography.pxToRem(436),
        [theme.breakpoints.down("sm")]: {
            width: theme?.typography.pxToRem(343),
        },
    },
}));


export const StyledModalActions = styled(ModalActions)(({ theme }: { theme?: any }) => ({
    [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
    },
}));
