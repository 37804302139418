import styled from "@emotion/styled";
import { Box } from "@mediaspace/shared/styled";
import FormLabelWithDescription
    from "./../form/form-label-with-description/FormLabelWithDescription";

export const StyledFormLabel = styled(FormLabelWithDescription)(
    ({ theme }: { theme?: any }) => ({
        [`&`]: {
            marginBottom: theme.spacing(1)
        }
    })
);

export const Container = styled(Box)(({ theme }: { theme?: any }) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        display: "block"
    }
}));
