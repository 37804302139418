import styled from "@emotion/styled";
import { useRef, useState, useContext } from "react";
import { IconButton, Link } from "@kaltura/ds-react-components";
import TruncateMarkup from "react-truncate-markup";
import { translate } from "@mediaspace/shared/utils";
import { CategoryLinkAnalyticsContext } from "@mediaspace/shared/entry";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { CategoryBubble, CategoryBubbleType } from "./CategoryBubble";

export interface CategoryBubblesProps {
    /**
     * list of categories to show
     */
    categories?: CategoryBubbleType[];
    entryId?: string;
    onDelete?: (categoryId: number) => void;
}

const StyledCategoryBubbles = styled("div")(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(3),
}));

const FirstLine = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    lineHeight: theme.spacing(4),
}));

const CategoryWrap = styled("div")(({ theme }) => ({
    flexGrow: 1,
}));

const FirstLineCategories = styled("div")(({ theme }) => ({
    display: "inline-flex",
    gap: theme.spacing(1),
    lineHeight: theme.spacing(4),
}));

const MoreCategories = styled("div")(({ theme }) => ({
    display: "flex",
    rowGap: theme.spacing(1),
    flexWrap: "wrap",
    lineHeight: theme.spacing(4),
    marginBottom: theme.spacing(1),
}));

const MoreButton = styled(IconButton)(({ theme }) => ({
    height: theme.spacing(3),
    width: theme.spacing(3),
    color: "inherit",
    backgroundColor: theme.kaltura.palette.tone7,
    position: "relative",
    top: 1,
}));

const LessButton = styled(Link)(({ theme, component }: { theme?: any; component?: "button" }) => ({
    textDecoration: "none",
    fontSize: theme.typography.fontSize,
}));

/**
 *  category bubbles - kms published category list with truncation
 */
export function CategoryBubbles({ categories = [], entryId, onDelete }: CategoryBubblesProps) {
    const visibleCategories = useRef(0);
    const [showMore, setShowMore] = useState(false);

    const { sendCategoryLinkAnalytics, sendCategoryBubbleAnalytics } = useContext(CategoryLinkAnalyticsContext);
    const sendButtonAnalytics = useButtonAnalytics();

    const handleDelete = (category: CategoryBubbleType) => {
        sendCategoryBubbleAnalytics();
        onDelete(category.id);
    }

    const handleShowMore = () => {
        setShowMore((showMore) => !showMore);

        sendButtonAnalytics(
            "Publish - all publish boxes category list -  Expand categories",
            ButtonClickAnalyticsType.EXPAND,
            entryId
        );
    };

    const handleShowLess = () => {
        setShowMore((showMore) => !showMore);

        sendButtonAnalytics(
            "Publish - all publish boxes category list -  Collapse categories",
            ButtonClickAnalyticsType.COLLAPSE,
            entryId
        );
    };

    /*
    use truncate lib's ellipsis function to check how many categories
    fit in the first line with the open/close arrow.
    when opened, add a new div with the additional categories below
    the first line - not in the same container - this way the
    flex layout will spread them properly.
     */
    const tagsLeftEllipsis = (node: any) => {
        const displayedCategories = node.props.children;
        visibleCategories.current = displayedCategories ? displayedCategories.length : 0;

        // calling setState here causes endless render loop, so using ref instead
        return visibleCategories.current > 0 && !showMore ? (
            <MoreButton variant={"borderless"} onClick={handleShowMore}>
                {`+${categories.length - visibleCategories.current - 1}`}
            </MoreButton>
        ) : (
            <span />
        );
    };

    return (
        <StyledCategoryBubbles>
            {!showMore && (
                <FirstLine>
                    <CategoryWrap>
                        <FirstLineCategories>
                            <TruncateMarkup lines={1} ellipsis={tagsLeftEllipsis}>
                                <span>
                                    {categories.map((category, ind) => (
                                        <TruncateMarkup.Atom key={`cat_${ind}`}>
                                            <CategoryBubble
                                                category={category}
                                                onClick={category.url ? sendCategoryLinkAnalytics : null}
                                                onDelete={onDelete ? () => handleDelete(category) : null}
                                            />
                                        </TruncateMarkup.Atom>
                                    ))}
                                </span>
                            </TruncateMarkup>
                        </FirstLineCategories>
                    </CategoryWrap>
                </FirstLine>
            )}
            {showMore && (
                <>
                    <MoreCategories>
                        <CategoryWrap>
                            {categories.map((category, ind) => (
                                <CategoryBubble
                                    key={`cat_${ind}`}
                                    category={category}
                                    onClick={category.url ? sendCategoryLinkAnalytics : null}
                                    onDelete={onDelete ? () => handleDelete(category) : null}
                                />
                            ))}
                        </CategoryWrap>
                    </MoreCategories>
                    <LessButton component="button" onClick={handleShowLess}>
                        {translate("show less")}
                    </LessButton>
                </>
            )}
        </StyledCategoryBubbles>
    );
}
