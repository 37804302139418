import { Button, ButtonProps } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import styled from "@emotion/styled";

const StyledButton = styled(Button)(({ theme }: { theme?: any }) => ({
    marginRight: theme.spacing(2)
}));

const CancelButton = (props: ButtonProps) => {
    const { onClick, disabled } = props;

    return (
        <StyledButton
            onClick={onClick}
            disabled={disabled}
            size={"medium"}
            variant={"borderless"}
        >
            {translate("Cancel")}
        </StyledButton>
    );
};

export default CancelButton;
