import styled from "@emotion/styled";
import { Alert } from "@kaltura/ds-react-components";
import { KmsTypePublishEntryCategories } from "@mediaspace/shared/types";
import { CategoryBubbles } from "../category-bubbles/CategoryBubbles";
import { translate } from "@mediaspace/shared/utils";
import { TitledCategoryLinks } from "../category-links/TitledCategoryLinks";
import { addCategoryUrl } from "../../utils";

export interface PublishedCategoriesProps {
    entryCategories: KmsTypePublishEntryCategories;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const StyledPublishedCategories = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

/**
 * published categories - media published categories (in the current instance)
 */
export function PublishedCategories({ entryCategories }: PublishedCategoriesProps) {
    const { channels = {}, galleries = {}, privateCategories } = entryCategories;

    // get the published categories
    const { published: publishedChannels = [] } = channels;
    const { published: publishedGalleries = [] } = galleries;
    const publishedLocations = addCategoryUrl(publishedChannels, publishedGalleries);

    // get the pending categories
    const { pending: pendingChannels = [] } = channels;
    const { pending: pendingGalleries = [] } = galleries;
    const pendingLocations = addCategoryUrl(pendingChannels, pendingGalleries);

    // get the rejected categories
    const { rejected: rejectedChannels = [] } = channels;
    const { rejected: rejectedGalleries = [] } = galleries;
    const rejectedLocations = addCategoryUrl(rejectedChannels, rejectedGalleries);

    return (
        <>
            {privateCategories && (
                <StyledAlert severity="warning">{translate("Media is published to a private location")}</StyledAlert>)}

            {publishedLocations.length > 0 && (
                <StyledPublishedCategories className="kms-ds-publish-media-categories-published">
                    <CategoryBubbles categories={publishedLocations} />
                </StyledPublishedCategories>
            )}

            <TitledCategoryLinks
                className="kms-ds-publish-media-categories-pending"
                categories={pendingLocations}
                title={translate("Pending in:")}
                color={"warning.main"}
            ></TitledCategoryLinks>

            <TitledCategoryLinks
                className="kms-ds-publish-media-categories-rejected"
                categories={rejectedLocations}
                title={translate("Rejected in:")}
                color={"error.main"}
            ></TitledCategoryLinks>
        </>
    );
}
