import { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

let nextId = 0;

export interface PlayerProps {
    /**
     * player config
     * @see https://kaltura.github.io/kaltura-player-js/docs/configuration.html
     */
    config: any;

    /**
     * player error handler
     * @param error
     */
    onError?: (error: { message:string, severity: number }) => void;

    /**
     * player ready handler
     * @param player reference to the player instance
     */
    onReady?: (player: any, playerId: string) => void;
    className?: string;
}

const StyledPlayer = styled('div')(
    ({ theme }: { theme?: any }) => ({
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
    })
);

/**
 * V7 rapt player.
 * this component is pretty similar to EmptyPlayer component, but uses
 * PathKalturaPlayer object instead of KalturaPlayer for setup
 */
const Player = (props: PlayerProps) => {

    const {onError, onReady, config, className} = props;

    const [player, setPlayer] = useState<any>();
    const playerId = useRef(`js-kaltura-rapt-player-${nextId++}`);
    const playerReadyCalled = useRef(false);

    const handlePlayerError = (event: any) => {
        if (onError) {
            onError(event.payload);
        }
        else {
            console.log(event.payload);
        }
    }

    useEffect(() => {

        const { PathKalturaPlayer } = window as any;
        if (!PathKalturaPlayer) {
            const message = "PathKalturaPlayer library is not detected. Please include it in your page.";
            handlePlayerError({message, severity: 3 /* fatal */ });
            return () => void(0);
        }

        try {
            const p = window.kalturaPlayer = PathKalturaPlayer.setup({
                ...config,
                targetId: playerId.current,
            });

            // delay call to playerReady until after the player is done initializing for real.
            setPlayer(p);

        } catch (e) {
            console.log("error setting up player:", e);
        }
        return () => void(0);
    }, [])

    useEffect(() => {
        if (player && onReady && !playerReadyCalled.current) {
            playerReadyCalled.current = true;
            onReady(player, playerId.current);
        }
    }, [player, onReady])

    return (
        <StyledPlayer
            id={playerId.current}
            className={`kaltura-player ${className}`}
        />
    );
}

export default Player;
