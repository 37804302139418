import styled from "@emotion/styled";
import { KmsTypePublishCategories } from "@mediaspace/shared/types";
import { translate } from "@mediaspace/shared/utils";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { CategoryBubbleType } from "../../../../../../media-categories/category-bubbles/CategoryBubble";
import { ExceptionsLocations } from "./execptions-locations/ExceptionsLocations";
import { addCategoryLabel } from "../../../../../../utils";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface ExceptionsTabProps {
    entryId: string,
    channels: KmsTypePublishCategories;
    galleries: KmsTypePublishCategories;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
}

const StyledContainer = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1)
}));

/**
 * publish modal exceptions tab
 * shows entry pending+rejected categories in current instance
 */
export function ExceptionsTab({ entryId, channels, galleries, onCategoryChange }: ExceptionsTabProps) {

    const sendButtonAnalytics = useButtonAnalytics();

    // get the pending categories
    const { pending: pendingChannels = [] } = channels;
    const { pending: pendingGalleries = [] } = galleries;
    const pendingLocations = addCategoryLabel(pendingChannels, pendingGalleries);

    // get the rejected categories
    const { rejected: rejectedChannels = [] } = channels;
    const { rejected: rejectedGalleries = [] } = galleries;
    const rejectedLocations = addCategoryLabel(rejectedChannels, rejectedGalleries);

    const handleDelete = (category: CategoryBubbleType) => {
        sendButtonAnalytics(
            "Publish - Set publishing settings modal box - Remove media from exception",
            ButtonClickAnalyticsType.DELETE,
            entryId
        );
        onCategoryChange(category.id, false);
    }

    return (
        <StyledContainer>
            {pendingLocations.length > 0 &&
                <ExceptionsLocations
                    title={translate("Pending in:")}
                    color={"warning.main"}
                    categories={pendingLocations}
                    onDelete={handleDelete}
                />
            }
            {rejectedLocations.length > 0 &&
                <ExceptionsLocations
                    title={translate("Rejected in:")}
                    color={"error.main"}
                    categories={rejectedLocations}
                    onDelete={handleDelete}
                />
            }
        </StyledContainer>
    );
}
