import styled from "@emotion/styled";
import { useState } from "react";
import { MenuItem, Alert } from "@kaltura/ds-react-components";
import { Lock24Icon } from "@kaltura/ds-react-icons";
import { Link24Icon } from "@kaltura/ds-react-icons";
import { Globe24Icon } from "@kaltura/ds-react-icons";
import { Check16Icon } from "@kaltura/ds-react-icons";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import ReactHtmlParser from "@mediaspace/shared/ui/html-react-parser";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { PublishStatus } from "@mediaspace/shared/types";
import { ModalManager } from "./modals/ModalManager";

export interface PublishMenuItemsProps {
    entryIds: string[];
    publishStatus: PublishStatus;
    enableUnlisted?: boolean;
    crossApplicationUnpublishing?: boolean;
    allowUnpublishPublished?: boolean;
    allowPublish?: boolean;
    errorMessages?: string[];
    warningMessages?: string[];
    onCloseMenu?: () => void;
    onItemStateChange?: (key: string, value: any) => void;
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    height: "auto",
    maxWidth: 330,
    ["& > div"]: {
        height: "auto",
    },
}));

const StyledAlertMenuItem = styled(MenuItem)(({ theme }) => ({
    height: "auto",
    maxWidth: 330,
    marginTop: theme.spacing(1),
    ["&:focus, &:focus > div, &:hover, &:hover > div"]: {
        backgroundColor: "transparent",
    },
}));

const StyledIconWrapper = styled("div")(({ theme }) => ({
    display: "inline-flex",
    position: "relative",
    top: 2,
    marginRight: theme.spacing(1),
}));

const StyledLabelWrapper = styled("div")(() => ({
    display: "inline-flex",
    position: "relative",
    bottom: 4,
    flexDirection: "column",
    maxWidth: 236,
    textWrap: "wrap",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    whiteSpace: "break-spaces",
    ["& a"]: {
        color: theme.kaltura.palette.primary.main,
    },
}));

/**
 * Publish indication menu items
 *
 * meant to be used in menu: <Menu><PublishMenuItems /></Menu> (my media, upload)
 * or in nested menu items:  <Menu><NestedMenuItem><PublishMenuItems /></NestedMenuItem></Menu> (media page)
 * and so - the top level component MUST be a fragment <></>
 */
export function PublishMenuItems(props: PublishMenuItemsProps) {
    const {
        entryIds,
        publishStatus,
        enableUnlisted = true,
        crossApplicationUnpublishing = false,
        allowUnpublishPublished = true,
        allowPublish = true,
        warningMessages,
        errorMessages,
        onCloseMenu,
        onItemStateChange,
    } = props;

    const [open, setOpen] = useState(false);
    const [targetStatus, setTargetStatus] = useState<PublishStatus>();

    const sendButtonAnalytics = useButtonAnalytics();

    const isPrivate = publishStatus === PublishStatus.PRIVATE;
    const isUnlisted = publishStatus === PublishStatus.UNLISTED;
    const isPublished = publishStatus === PublishStatus.PUBLISHED;

    const handleSubmit = (result: boolean, targetStatus: PublishStatus) => {
        // update state
        onItemStateChange?.('publishStatus',targetStatus);
        // close modal
        setOpen(false);
        // close menu
        onCloseMenu?.();
    }

    const handleClose = () => {
        // close modal
        setOpen(false);
    }

    const handlePrivateClick = () => {
        isPrivate && onCloseMenu?.();
        setOpen(!isPrivate);
        setTargetStatus(PublishStatus.PRIVATE);
        !isPrivate && sendButtonAnalytics("Publishing indicator menu - Private", ButtonClickAnalyticsType.CHOOSE);
    };

    const handleUnlistedClick = () => {
        isUnlisted && onCloseMenu?.();
        setOpen(!isUnlisted);
        setTargetStatus(PublishStatus.UNLISTED);
        !isUnlisted && sendButtonAnalytics("Publishing indicator menu - Unlisted", ButtonClickAnalyticsType.CHOOSE);
    };

    const handlePublishClick = () => {
        setOpen(true);
        setTargetStatus(PublishStatus.PUBLISHED);
        isPublished
            ? sendButtonAnalytics(
                  "Publishing indicator menu - Update publish locations",
                  ButtonClickAnalyticsType.CHOOSE
              )
            : sendButtonAnalytics("Publishing indicator menu - Publish", ButtonClickAnalyticsType.CHOOSE);
    };

    return (
        <>
            <StyledMenuItem
                key="private"
                className="kms-ds-publish-menu-item-private"
                onClick={handlePrivateClick}
                autoFocus={isPrivate}
                icon={isPrivate ? <Check16Icon /> : undefined}
                aria-selected={isPrivate}
                disabled={!allowUnpublishPublished && isPublished}
            >
                <StyledIconWrapper>
                    <Lock24Icon />
                </StyledIconWrapper>
                <StyledLabelWrapper>
                    {translate("Private")}
                    <StyledTypography variant="body2">{translate("Only I can view")}</StyledTypography>
                </StyledLabelWrapper>
            </StyledMenuItem>
            {enableUnlisted && (
                <StyledMenuItem
                    key="unlisted"
                    className="kms-ds-publish-menu-item-unlisted"
                    onClick={handleUnlistedClick}
                    autoFocus={isUnlisted}
                    icon={isUnlisted ? <Check16Icon /> : undefined}
                    aria-selected={isUnlisted}
                    disabled={!allowUnpublishPublished && isPublished}
                >
                    <StyledIconWrapper>
                        <Link24Icon />
                    </StyledIconWrapper>
                    <StyledLabelWrapper>
                        {translate("Unlisted")}
                        <StyledTypography variant="body2">
                            {translate("Anyone with the link can view")}
                        </StyledTypography>
                    </StyledLabelWrapper>
                </StyledMenuItem>
            )}
            <StyledMenuItem
                key="publish"
                className="kms-ds-publish-menu-item-publish"
                onClick={handlePublishClick}
                autoFocus={isPublished}
                icon={isPublished ? <Check16Icon /> : undefined}
                aria-selected={isPublished}
                disabled={!allowPublish}
            >
                <StyledIconWrapper>
                    <Globe24Icon />
                </StyledIconWrapper>
                <StyledLabelWrapper>
                    {isPublished ? translate("Change publish locations") : translate("Publish")}
                    <StyledTypography variant="body2">
                        {translate("Anyone with entitlements to the gallery/channel can view")}
                    </StyledTypography>
                </StyledLabelWrapper>
            </StyledMenuItem>
            {warningMessages &&
                warningMessages.map((warningMessage, index) => (
                    <StyledAlertMenuItem key={`warn_${index}`}>
                        <StyledAlert severity="warning" icon={false}>
                            {ReactHtmlParser(warningMessage)}
                        </StyledAlert>
                    </StyledAlertMenuItem>
                ))}
            {errorMessages &&
                errorMessages.map((errorMessage, index) => (
                    <StyledAlertMenuItem key={`err_${index}`}>
                        <StyledAlert severity="error" icon={false}>
                            {ReactHtmlParser(errorMessage)}
                        </StyledAlert>
                    </StyledAlertMenuItem>
                ))}

            { /* mount modals to force data fetch */}
            {open && (
                <ModalManager
                    entryIds={entryIds}
                    publishStatus={publishStatus}
                    targetStatus={targetStatus}
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                />
            )}
        </>
    );
}

export default PublishMenuItems;
