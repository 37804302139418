import styled from "@emotion/styled";
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DatePicker, DEFAULT_TIME_FORMAT, TimePicker } from "@kaltura/ds-react-components";
import dayjs, { Dayjs } from "dayjs";

export interface DateFieldWithTimeProps {
    id?: string,

    /**
     * current value to show in the picker
     */
    value?: Date,

    onChange: (value: Date) => void,

    onBlur: () => void,

    error?: boolean,

    /**
     * date format to show in the date picker
     */
    dateFormat?: string,

    timeFormat?: string,
}

const StyledDateField = styled.div(({ theme }: { theme?: any }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
    // margin: theme.spacing(1, 0),
}));

const DateFieldWithTime = forwardRef<HTMLInputElement, DateFieldWithTimeProps>(({
                                                        id,
                                                        value,
                                                        onChange,
                                                        onBlur,
                                                        error,
                                                        dateFormat,
                                                        timeFormat = DEFAULT_TIME_FORMAT}: DateFieldWithTimeProps, ref) => {

    const isMounted = useRef(false);
    const datePickerInputProps = {classes: {root: "chromatic-ignore"}, onBlur: onBlur};

    const [timeValue, setTimeValue] = useState(() => {
        const m = dayjs(value);
        return m.isValid() ? m.format(timeFormat) : null;
    });
    const [dateValue, setDateValue] = useState(value);

    const dateValueAsDayjs = useMemo(() => dayjs(dateValue), [dateValue]);

    const [lastValue, setLastValue] = useState<Date>();

    const onDateChange = useCallback((newDate: Dayjs) => { // this is dayjs date, not js Date!!!
        const date = newDate && newDate.isValid() ? newDate.toDate() : null;
        setDateValue(date);
    }, []);

    const onTimeChange = useCallback((newTime:string) => {
        setTimeValue(newTime);
    }, []);

    useEffect(() => {
        // avoid firing on initial mount
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }
        // get the date from dateValue
        const newValue = dateValue ? new Date(dateValue) : null;
        // if we have date and time values
        if (newValue && timeValue) {
            // get the time from timeValue
            const t = new Date(`1/1/1900 ${timeValue}`);
            // add time to date
            newValue.setHours(t.getHours(), t.getMinutes(), 0, 0);
        }
        // only fire for real changes
        if (lastValue !== newValue) {
            setLastValue(newValue);
            onChange(newValue);
        }

    }, [dateValue, timeValue]);


    return (
        <StyledDateField >
            <DatePicker inputProps={datePickerInputProps}
                        inputRef={ref}
                        inputFormat={dateFormat}
                        value={dateValueAsDayjs.isValid() ? dateValueAsDayjs : null}
                        onChange={onDateChange}/>
            <TimePicker onChange={onTimeChange} timeFormat={timeFormat} defaultValue={timeValue} />
        </StyledDateField>
    );
});

export default DateFieldWithTime;
