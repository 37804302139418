import styled from "@emotion/styled";
import { StyledInput } from "@mediaspace/shared/ui/form/FormElementStyles";
import { ChangeEventHandler } from "react";
import { IconButton } from "@kaltura/ds-react-components";
import { Remove24Icon } from "@kaltura/ds-react-icons";

export interface SingleTextProps {
    htmlId: string;
    id: number;
    error?: boolean;
    value: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    onRemove: (id: number) => void;
}

const StyledSingleText = styled.div(({ theme }: { theme?: any }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
    margin: theme.spacing(1, 0),
}));

const StyledIconButton = styled(IconButton)(({ theme }: { theme?: any }) => ({
    color: theme.kaltura.palette.tone3,
}));

function SingleText({htmlId, id, error, value, onChange, onRemove}: SingleTextProps) {
    return (
        <StyledSingleText>
            <StyledInput id={htmlId} error={error} value={value} onChange={onChange}/>
            <StyledIconButton variant={"borderless"} onClick={() => onRemove(id)}><Remove24Icon /></StyledIconButton>
        </StyledSingleText>
    );
}

export default SingleText;
