import { KmsTypePublishCategory, KmsTypeCategoryMembership } from "@mediaspace/shared/types";
import { Tooltip } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import { Globe24Icon } from "@kaltura/ds-react-icons";
import { NoAccess24Icon } from "@kaltura/ds-react-icons";
import { Lock24Icon } from "@kaltura/ds-react-icons";
import { Unlock24Icon } from "@kaltura/ds-react-icons";
import { Visible24Icon } from "@kaltura/ds-react-icons";
import { Group24Icon } from "@kaltura/ds-react-icons";
import { Expert24Icon } from "@kaltura/ds-react-icons";

interface CategoryIconProps {
	category: KmsTypePublishCategory;
}

/**
 * Category membership and moderation icons
 */
export function CategoryIcon({ category }: CategoryIconProps) {
	const getMembershipIcon = () => {
		switch (category.membership) {
			case KmsTypeCategoryMembership.OPEN:
				return (
					<Tooltip
						title={translate(
							"Open - All logged-in users can view and contribute content (contribution is not allowed for viewer-role users)."
						)}
					>
						<Globe24Icon />
					</Tooltip>
				);

			case KmsTypeCategoryMembership.RESTRICTED:
				return (
					<Tooltip
						title={translate(
							"Restricted - All logged-in users can view content, only channel members can contribute."
						)}
					>
						<NoAccess24Icon />
					</Tooltip>
				);

			case KmsTypeCategoryMembership.PRIVATE:
				return (
					<Tooltip title={translate("Private - Only channel members can view and contribute content.")}>
						<Lock24Icon />
					</Tooltip>
				);

			case KmsTypeCategoryMembership.SHARED:
				return (
					<Tooltip
						title={translate(
							"Shared Repository - Only members can view and contribute content. Content can be published to other locations based on entitlements."
						)}
					>
						<Group24Icon />
					</Tooltip>
				);

			case KmsTypeCategoryMembership.PUBLIC_RESTRICTED:
				return (
					<Tooltip
						title={translate(
							"Public, Restricted - Anyone can view content (including guests). Only channel members can contribute according to their publishing entitlements."
						)}
					>
						<Unlock24Icon />
					</Tooltip>
				);

			case KmsTypeCategoryMembership.PUBLIC_OPEN:
				return (
					<Tooltip
						title={translate(
							"Public, Open - Anyone can view content (including guests). All logged in users can contribute content (contribution is not allowed for viewer-role users)."
						)}
					>
						<Visible24Icon />
					</Tooltip>
				);

			default:
				return null;
		}
	};

	const getModarationIcon = () => {
		if (category.moderation) {
			return (
				<Tooltip title={translate("Moderated - Content will only be available after moderator approval.")}>
					<Expert24Icon />
				</Tooltip>
			);
		}
		return null;
	};

	const icon = getMembershipIcon();
	const moderationIcon = getModarationIcon();

	return (
		<>
			{icon}
			{moderationIcon}
		</>
	);
}
