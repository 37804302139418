import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "@mediaspace/shared/styled";
import { Typography } from "@kaltura/ds-react-components";
import StyledIllustration from "@mediaspace/shared/ui/styled-illustration/StyledIllustration";

const progressAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const StyledBox = styled(Box)(({ theme }: { theme?: any }) => ({
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme?.spacing(2),
}));

export const StyledProgressIcon = styled("svg")(({ theme }: { theme?: any }) => ({
    animation: `${progressAnimation}  infinite 1.7s linear`,
    height: "1rem",
    width: "1rem",
    marginLeft: theme?.spacing(1),
    marginTop: theme?.spacing(0.8),
    marginRight: theme?.spacing(1),
}));

export const Spinner = <StyledIllustration fileName={"DownloadSpinner.svg"} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g className="Spinner2">
        <g className="GeneralSpinner">
            <path fill="#006EFA" d="M3.803 6.916a4.336 4.336 0 0 1 7.694-1.476l-.691-.097a1 1 0 0 0-.278 1.98l2.513.354a1 1 0 0 0 1.066-.616l.951-2.353a1 1 0 0 0-1.854-.75l-.112.276A6.336 6.336 0 0 0 1.866 6.417a1 1 0 1 0 1.937.499Zm4.198 5.417a4.336 4.336 0 0 0 4.197-3.249 1 1 0 1 1 1.937.499A6.336 6.336 0 0 1 2.91 11.766l-.111.275a1 1 0 1 1-1.855-.749l.951-2.353a1 1 0 0 1 1.067-.616l2.513.353a1 1 0 0 1-.278 1.98l-.692-.096a4.329 4.329 0 0 0 3.497 1.773Z" className="Primary"/>
        </g>
    </g>
</StyledIllustration>


export const StyledSeparator = styled("span")(({ theme }: { theme?: any }) => ({
    marginLeft: theme?.spacing(1),
    marginRight: theme?.spacing(1)
}));

export const Label = styled(Typography, {
    shouldForwardProp: prop =>
        prop !== 'error'
})<{ error: boolean} >(({theme, error} : { theme?: any, error?: boolean }) => ({
    ...(error && {
        color: theme?.kaltura.palette.danger.main
    })
}));
