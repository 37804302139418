import { KmsTypePublishCategory } from "@mediaspace/shared/types";
import { addBaseUrl, encodeEntryNameForURI, translate } from "@mediaspace/shared/utils";

export const galleryLabel = (gallery: KmsTypePublishCategory) =>
    translate("%1 (Gallery ID: %2)", [gallery.name, gallery.id]);
export const channelLabel = (channel: KmsTypePublishCategory) =>
    translate("%1 (Channel ID: %2)", [channel.name, channel.id]);

const addGalleryUrl = (galleries: KmsTypePublishCategory[]) => {
    return galleries.map((gallery) => ({
        ...gallery,
        url: addBaseUrl(`/category/${encodeEntryNameForURI(gallery.name)}/${gallery.id}`),
        label: galleryLabel(gallery),
    }));
};

const addChannelUrl = (channels: KmsTypePublishCategory[]) => {
    return channels.map((channel) => ({
        ...channel,
        url: addBaseUrl(`/channel/${encodeEntryNameForURI(channel.name)}/${channel.id}`),
        label: channelLabel(channel),
    }));
};

export const addCategoryUrl = (channels: KmsTypePublishCategory[], galleries: KmsTypePublishCategory[]) => {
    // add channel url
    const channelLocations = addChannelUrl(channels);
    // add gallery url
    const galleryLocations = addGalleryUrl(galleries);
    // concat the channels and galleries
    return [...galleryLocations, ...channelLocations];
};

const addGalleryLabel = (galleries: KmsTypePublishCategory[]) => {
    return galleries.map((gallery) => ({
        ...gallery,
        label: galleryLabel(gallery),
    }));
};

const addChannelLabel = (channels: KmsTypePublishCategory[]) => {
    return channels.map((channel) => ({
        ...channel,
        label: channelLabel(channel),
    }));
};

export const addCategoryLabel = (channels: KmsTypePublishCategory[], galleries: KmsTypePublishCategory[]) => {
    // add channel label
    const channelLocations = addChannelLabel(channels);
    // add gallery label
    const galleryLocations = addGalleryLabel(galleries);
    // concat the channels and galleries
    return [...galleryLocations, ...channelLocations];
};

export const addExternalCategoryLabel = (categories: KmsTypePublishCategory[]) => {
    return categories.map((category) => ({
        ...category,
        label: translate("Category ID: %1", [category.id]),
    }));
};
