// Source: https://github.com/kaltura/kep-ve-management/blob/master/ve-management/apps/ve-client/src/app/app-ui/shared-ui/toasts-context.tsx
import React, {useContext} from "react";

export interface ToastOptions {
  message: string | Element;
  severity: "error" | "warning" | "info" | "success";
}

export type ToastsContextValue = {
  showToast: (options: ToastOptions) => void;
};

export const toastsContextDefaultValue: ToastsContextValue = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showToast: (options: ToastOptions) => {
    throw new Error("No toasts provider");
  },
};

/**
 * React context for pushing new toast notifications into the global notifications area.
 * See ToastsProvider.
 */
export const ToastsContext = React.createContext<ToastsContextValue>(toastsContextDefaultValue);

/**
 * React hook for pushing new toast notifications into the global notifications area.
 * See ToastsProvider.
 *
 * Usage:
 * ```
 * const { showToast } = useToastsContext();
 *
 * return <Button onClick={() => showToast({severity: "info", message: "Hi there!"});}>
 *     Notify me!
 * </Button>;
 * ```
 */
export const useToastsContext = (): ToastsContextValue => {
  return useContext(ToastsContext);
};
