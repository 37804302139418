import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { Skeleton } from "@mediaspace/shared/styled";

const StyledMediaCategoriesSkeleton = styled("div")(({ theme }: { theme?: any }) => ({
    paddingTop: theme.spacing(3),
    maxWidth: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
}));

const StyledSkeleton = styled(Skeleton)(({ theme }: { theme?: any }) => ({
    borderRadius: theme.kaltura.shape.roundness1,
    marginBottom: theme.spacing(2),
}));

/**
 * publish modal MediaCategories skeleton
 */
export function MediaCategoriesSkeleton() {
    const theme = useTheme();

    return (
        <>
            <StyledMediaCategoriesSkeleton>
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"40%"} />
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"25%"} />
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"25%"} />
            </StyledMediaCategoriesSkeleton>
        </>
    );
}
