import { Player } from "@mediaspace/player";
import React, { MouseEvent, useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import { baseUrl, getEntryUrl, translate } from "@mediaspace/shared/utils";
import { Button } from "@kaltura/ds-react-components";
import { StyledTypography } from "@mediaspace/features/certificates/error-modal/StyledComponents";
import { ArrowRight24Icon, Download24Icon, Edit24Icon, Link24Icon } from "@kaltura/ds-react-icons";
import {
    CenteredContainer,
    CenteredContentContainer,
    playerContainerDimensions,
    StyledQuizActions,
    StyledTitle,
    VerticallyCenteredContainer,
    Wrapper,
} from "./StyledComponents";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface AiQuizPreviewProps {
    playerConfig: any;
    ks: string;
    entryId: string;
    questionsDownloadUrl: string;
}

/**
 * Generate AI quiz preview
 */
export const AiQuizPreview = ({ playerConfig, ks, entryId, questionsDownloadUrl }: AiQuizPreviewProps) => {
    const sendButtonAnalytics = useButtonAnalytics();

    const getQuizEntryURL = useCallback(
        (isEdit?: boolean) =>
            !isEdit ? getEntryUrl(entryId) : `${baseUrl}/editor/index/launch-editor?entryId=${entryId}&tab=quiz`,
        [entryId]
    );

    const copyLink = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            const url = getQuizEntryURL();
            navigator.clipboard.writeText(url);
            sendButtonAnalytics("Quiz AI Actions - Copy link", ButtonClickAnalyticsType.SHARE);
        },
        [getQuizEntryURL]
    );

    const previewActions = useMemo(() => {
        return [
            {
                title: translate("Go to media page"),
                icon: <ArrowRight24Icon />,
                href: getQuizEntryURL(),
                handler: () => sendButtonAnalytics("Quiz AI Actions - Go to media", ButtonClickAnalyticsType.LINK),
            },
            {
                title: translate("Edit quiz"),
                icon: <Edit24Icon />,
                href: getQuizEntryURL(true),
                handler: () => sendButtonAnalytics("Quiz AI Actions - Edit quiz", ButtonClickAnalyticsType.EDIT),
            },
            {
                title: translate("Copy link"),
                icon: <Link24Icon />,
                handler: copyLink,
            },
            {
                title: translate("Download question list"),
                icon: <Download24Icon />,
                href: questionsDownloadUrl,
                handler: () =>
                    sendButtonAnalytics("Quiz AI Actions - Download list of Q", ButtonClickAnalyticsType.DOWNLOAD),
            },
        ];
    }, [copyLink, questionsDownloadUrl, getQuizEntryURL]);

    return (
        <Wrapper>
            <StyledTitle variant="h2" align={"center"} component={"h1"}>
                {translate("Create video quiz with AI")}
            </StyledTitle>
            <CenteredContentContainer>
                <PlayerWrapper>
                    <Player media={{ entryId, ks }} config={playerConfig} />
                </PlayerWrapper>
                <VerticallyCenteredContainer>
                    <CenteredContainer>
                        <StyledTypography variant={"h3"}>
                            {translate("Your video quiz was successfully created!")}
                        </StyledTypography>

                        <StyledQuizActions>
                            {previewActions.map((action, index) => (
                                <StyledButton
                                    color={"translucent"}
                                    size={"large"}
                                    href={action.href}
                                    onClick={action.handler}
                                    startIcon={action.icon}
                                    variant={"borderless"}
                                >
                                    {action.title}
                                </StyledButton>
                            ))}
                        </StyledQuizActions>
                    </CenteredContainer>
                </VerticallyCenteredContainer>
            </CenteredContentContainer>
        </Wrapper>
    );
};

const PlayerWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
    ...playerContainerDimensions(theme),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));
