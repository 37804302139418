import { MenuItem, NestedMenuItem } from "@kaltura/ds-react-components";
import { ChevronRight16Icon } from "@kaltura/ds-react-icons";
import { PublishMenuItems, PublishMenuItemsProps } from "@mediaspace/shared/entry";

export interface PublishMenuItemProps extends PublishMenuItemsProps {
    label: string;
}

/**
 * media page action publish menu item
 */
export function PublishMenuItem(props: PublishMenuItemProps) {
    const { label, ...rest } = props;

    return (
        <NestedMenuItem key={"publish"} MenuListProps={{ role: "listbox" }} label={label} icon={<ChevronRight16Icon />}>
            <PublishMenuItems {...rest} />
        </NestedMenuItem>
    );
}

export default PublishMenuItem;
