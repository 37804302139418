import React, { Suspense, useMemo } from "react";
import { baseUrl, fetchKmsData } from "@mediaspace/shared/utils";
import { ErrorBoundary } from "@mediaspace/shared/ui";
import { KmsTypePublishEntryCategories, KmsTypePublishUserCategories, PublishStatus } from "@mediaspace/shared/types";
import { PublishedModalSkeleton } from "./published-modal-skeleton/PublishedModalSkeleton";
import { PublishedModal } from "./PublishedModal";
import { ModalProps, StyledModal } from "../StyledModal";
import { translate } from "@mediaspace/shared/utils";
import { ModalTitle } from "@kaltura/ds-react-components";

export interface PublishModalContainerInnerProps extends ModalProps {
	fetchPublishLocations: () => {
		entryCategories?: KmsTypePublishEntryCategories;
		userCategories?: KmsTypePublishUserCategories;
	};
}

/**
 * Publish modal
 */
function PublishModalContainerInner(props: PublishModalContainerInnerProps) {
	const { fetchPublishLocations } = props;
	const locations = fetchPublishLocations();

	const { entryCategories, userCategories } = locations;

	return <PublishedModal entryCategories={entryCategories} userCategories={userCategories} {...props} />;
}

/**
 * Publish modal suspense container - fetch the media publish locations and the user potential publish locations,
 */
export const PublishedModalContainer = (props: ModalProps) => {
	const { entryIds, onClose } = props;

	const entryId = entryIds.length === 1 ? entryIds[0] : null;

	// fetch data from kms
	const fetchPublishLocations = useMemo(
		() =>
			fetchKmsData(baseUrl + "/publishmedia/media/get-categories/", {
				entryId: entryId,
				state: PublishStatus.PUBLISHED,
				showExternal: true,
			}),
		[entryId]
	);

	// render with Suspense
	return (
		<StyledModal
			open={true}
			className="kms-ds-publish-modal-publish"
			onMouseLeave={(event) => event.stopPropagation()}
		>
			<ModalTitle>{translate("Publish")}</ModalTitle>

			<ErrorBoundary fallback={<PublishedModalSkeleton onClose={onClose} />}>
				<Suspense fallback={<PublishedModalSkeleton onClose={onClose} />}>
					<PublishModalContainerInner
						fetchPublishLocations={fetchPublishLocations}
						{...props}
					></PublishModalContainerInner>
				</Suspense>
			</ErrorBoundary>
		</StyledModal>
	);
};
