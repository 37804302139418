import styled from "@emotion/styled";
import { SearchInput } from "@mediaspace/shared/ui";
import debounce from "lodash/debounce";

interface CategorySearchInputProps {
	placeholder: string;
	ariaLabel: string;
	onSearch: (keyword: string) => void;
	onResetSearch: () => void;
}

const StyledSearch = styled("div")(({ theme }) => ({
	marginTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	backgroundColor: theme.kaltura.palette.surfaces.background,
	position: "sticky",
	top: 0,
	zIndex: 1000,
}));

/**
 * category (channel/gallery) modal search input + debounce
 */
export function CategorySearchInput({ placeholder, ariaLabel, onSearch, onResetSearch }: CategorySearchInputProps) {
	const handleSearch = debounce((keyword: string) => {
		onSearch(keyword);
	}, 200);

	const handleReset = () => {
		handleSearch.cancel();
		onResetSearch();
	};

	return (
		<StyledSearch>
			<SearchInput
				color={"primary"}
				placeholder={placeholder}
				ariaLabel={ariaLabel}
				showIcon={true}
				onInputChange={(event) => {
					handleSearch(event.target.value);
				}}
				onReset={handleReset}
			/>
		</StyledSearch>
	);
}
