import {FunctionComponent, MouseEvent, useState} from "react";
import { IconButton, Menu, MenuItem, menuClasses } from "@kaltura/ds-react-components";
import { User24Icon } from "@kaltura/ds-react-icons";
import {addBaseUrl, translate} from "@mediaspace/shared/utils";
import { KmsTypeLinkLink } from "@mediaspace/shared/types/KmsTypeLinkLink";
import { Divider } from "@mediaspace/shared/ui";
import styled from "@emotion/styled";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { KmsTypeComponent } from "@mediaspace/shared/types";

export type UserMenuProps = {
    className?: string;
    disableScrollLock?: boolean;
    items: (KmsTypeLinkLink | "DIVIDER" | KmsTypeComponent)[];
};

const StyledMenuItem = styled(MenuItem)(
    ({ theme, component, href, target, rel }: { theme?: any, component: "a", href: string, target?: string, rel?: string }) => ({
        // override eventsplatform css
        ".eventsplatform &": {
            color: "inherit",
            [`&:hover`]: {
                color: "inherit",
            },
        },
        // override v2ui and bootstrap
        [`a&`]: {
            color: "inherit",
            [`&:hover, &:focus`]: {
                color: "inherit",
                textDecoration: "none",
            },
        }
    })
);

const StyledMenu = styled(Menu)(
    ({theme}) => ({
        [`& .${menuClasses.paper}`]: {
            [theme.breakpoints.down("sm")]:{
                top: "10px",
                maxHeight: "150px",
            },
        },
    })
);

const renderItem = (item: KmsTypeLinkLink | "DIVIDER" | KmsTypeComponent, index: number, sendButtonAnalytics: (actionName: string, buttonType: number) => void) => {
    if (item === "DIVIDER") {
        return <Divider key={`divider_${index}`} />;
    }

    const handleMenuItemClick = () => {
        if("attributes" in item || "value" in item){
            sendButtonAnalytics(`Header Menu - ${item.attributes?.["data-analytics-value"] ?? item.value}`, ButtonClickAnalyticsType.NAVIGATE);
        }
    }

    // custom menu item component
    if("component" in item) {
        const {component: ItemComponent, props: componentProps} = item;
        return <ItemComponent {...componentProps} key={index}/>;
    }

    return (
        <StyledMenuItem key={index} component={"a"}
            href={addBaseUrl(item.href)}
            target={item.attributes?.target as string ?? '_self'}
            rel={item.attributes?.rel as string ?? ''}
            onClick={handleMenuItemClick}
        >
            {item.label}
        </StyledMenuItem>
    );
};

const UserMenuButton = styled(IconButton)(({ theme }: { theme?: any }) => ({
    color: theme.palette.common.white,
    [`&:hover`]: {
        backgroundColor: "initial",
    },
}));

/**
 * User Menu component
 */
const UserMenu: FunctionComponent<UserMenuProps> = ({
    className = "",
    disableScrollLock = false,
    items,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={`kms-ds-user-menu ${className}`}>
            <div id="userMenuToggle">
                <UserMenuButton
                    color="translucent"
                    id={"userMenuToggleBtn"}
                    aria-haspopup={true}
                    aria-expanded={open ? "true" : "false"}
                    onClick={handleButtonClick}
                    aria-controls={"userMenuToggleMenu"}
                    aria-label={translate("user menu")}
                >
                    <User24Icon />
                </UserMenuButton>
                <StyledMenu
                    id={"userMenuToggleMenu"}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    aria-labelledby={"userMenuToggleBtn"}
                    disableScrollLock={disableScrollLock}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {items.map((item: KmsTypeLinkLink | "DIVIDER" | KmsTypeComponent, index: number) => renderItem(item, index, sendButtonAnalytics))}
                </StyledMenu>
            </div>
        </div>
    );
};

export default UserMenu;
