import styled from "@emotion/styled";
import { KmsTypePublishCategory } from "@mediaspace/shared/types";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Checkbox, Tooltip } from "@kaltura/ds-react-components";
import { CategoryIcon } from "../category-icon/CategoryIcon";
import { galleryLabel } from "../../../../../../utils";

interface GalleryCheckboxProps {
    gallery: KmsTypePublishCategory;
    disabled?: boolean;
    depth: number;
    isChecked: (galleryId: number) => boolean;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    items: { [key: string]: GalleryCheckboxProps };
}

const StyledFormControlLabel = styled(FormControlLabel)<{ depth?: number }>(({ theme, depth = 1 }) => ({
    marginLeft: theme.spacing(2 + 3.25 * (depth - 1)),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
    svg: {
        width: 16,
        height: 16,
        marginRight: theme.spacing(0.5),
        position: "relative",
        top: 3,
    },
}));

/**
 * gallery checkbox - recursively render checkbox and its items
 */
export const GalleryCheckbox = (props: GalleryCheckboxProps) => {
    const { isChecked, onCategoryChange, gallery, disabled = false, depth, items } = props;

    const childItems = Object.entries(items);

    return (
        <>
            {gallery && (
                <StyledFormControlLabel
                    key={gallery.id}
                    depth={depth}
                    control={
                        <StyledCheckbox
                            value={gallery.id}
                            disabled={disabled}
                            checked={isChecked(gallery.id)}
                            onChange={(event, checked) => onCategoryChange(gallery.id, checked)}
                        />
                    }
                    label={
                        <StyledLabel variant="body2Highlight">
                            <CategoryIcon category={gallery} />
                            <Tooltip title={galleryLabel(gallery)}>
                                <span>{gallery.name}</span>
                            </Tooltip>
                        </StyledLabel>
                    }
                ></StyledFormControlLabel>
            )}
            <>
                {childItems.map(([id, item]) => {
                    return (
                        <div key={item.gallery.id}>
                            <GalleryCheckbox {...props} {...item} depth={gallery ? depth + 1 : depth} />
                        </div>
                    );
                })}
            </>
        </>
    );
};
