import styled from "@emotion/styled";
import {Box} from "@mui/material";

export const StyledForm = styled("form")(({ theme }: { theme?: any }) => ({
    height: "100%",
    borderBottom: "1px solid",
    borderColor: theme?.kaltura.palette.tone4
}));

export const StyledActionsContainer = styled(Box)(({ theme }: { theme?: any }) => ({
    display: "flex",
    justifyContent: "end",
    marginTop: theme?.spacing(3),
    marginBottom: theme?.spacing(3)
}));
