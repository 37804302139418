import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { Modal } from "@kaltura/ds-react-components";
import { baseFormLabelClasses, FormLabel } from "@mediaspace/shared/ui";


export const StyledContainer = styled("div")(
    ({ theme }: { theme: Theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
    })
);

export const StyledCheckboxWrap = styled("div")(
    ({ theme }: { theme: Theme }) => ({
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(1),
    })
);
export const StyledCheckboxLabel = styled(FormLabel)(
    ({ theme }) => ({
        [`& .${baseFormLabelClasses.label}.${baseFormLabelClasses.label}`]: {
            paddingBottom: 0,
        }
    })
);

export const StyledModal = styled(Modal)(
    ({ theme }: { theme: Theme }) => ({
        "& .kms-ds-set-ownership-modal": {
            [theme.breakpoints.up("sm")]: {
                width: 584,
                height: 335,
            }
        }
    })
);

