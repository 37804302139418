import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { CategoryBubble, CategoryBubbleType } from "../../../../../../../media-categories/category-bubbles/CategoryBubble";

export interface ExceptionsLocationsProps {
    title: string;
    color: string;
    categories: CategoryBubbleType[];
    onDelete: (category: CategoryBubbleType) => void;
}

const StyledExceptionsContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1)
}));

/**
 * Publish exception locations
 * Shows current entry published+pending categories
 */
export function ExceptionsLocations({title, color, categories, onDelete}: ExceptionsLocationsProps) {

    return (
        <StyledExceptionsContainer className="kms-ds-publish-exceptions-locations-container">
            <Typography
                className="kms-ds-publish-exceptions-locations-title"
                variant="body2Highlight"
                color={color}
            >
                {title}
            </Typography>
            {categories.map((category, ind) => (
                <CategoryBubble
                    key={`cat_${ind}`}
                    category={category}
                    onDelete={() => onDelete(category)}
                />
            ))}
        </StyledExceptionsContainer>
    )
}
