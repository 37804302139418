import React, { ComponentType, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from "clsx";
import { translate } from "@mediaspace/shared/utils";
import { ChevronLeft24Icon, ChevronRight24Icon } from "@kaltura/ds-react-icons";
import {alpha, Box, composeClasses} from "@mediaspace/shared/styled";
import { CarouselClasses, getCarouselClass } from "./carouselClasses";
import { useTheme } from "@mediaspace/shared/styled";
import { systemWidth } from "@mediaspace/shared/styled";
import {useButtonAnalytics} from "@mediaspace/hooks";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";

type ItemProps<ItemT> = {
    item: ItemT;
    currentCardsNumberInSlides: 2 | 3 | 4 | 5;
    minPossibleCardsNumberInSlides: 2 | 3 | 4 | 5;
    onResize?: (height: number) => void;
};

type Props<ItemT, PropsT> = {
    active?: boolean;
    items: ItemT[];
    currentCardsNumberInSlides: 2 | 3 | 4 | 5; // amount of slides on the screen for large devices ("xl" and "lg")
    mdCardsNumberInSlides?: number; // amount of slides on the screen for medium devices ("md")
    minPossibleCardsNumberInSlides?: 2 | 3 | 4 | 5; // the smallest number of slides this carousel shows
    className?: string;
    itemComponent: ComponentType<ItemProps<ItemT> & PropsT>;
    itemProps?: PropsT | ((item: ItemT, index: number) => PropsT);
    classes?: Partial<CarouselClasses>;
    activeModal?: string;
    setActiveModal?: (value: string) => void;
    container?: string; // analytics action name depends on the container component of the carousel (e.g: "News" / "Playlist" ...)
    fullScreenWidth?: boolean;
};

const useUtilityClasses = (styleProps: Partial<Props<any, any>>) => {
    const { classes } = styleProps;

    const slots = {
        root: ["root"],
        item: ["item"],
        arrow: ["arrow"],
    };

    return composeClasses(slots, getCarouselClass, classes);
};

type ArrowProps = {
    className?: string;
    disabled?: boolean;
    iconClassName?: string;
    icon?: React.ReactElement;
    onClick?: () => void;
    style?: any;
    ariaLabel?: string;
    position?: string;
    container?: string;
    fullScreenWidth?: boolean;
    handleKeyDown?: (e) => void;
};

const preventRedirect = (e: React.FormEvent, sendButtonAnalytics, position = "", container = "") => {
    e.preventDefault();
    if (position && container) {
        sendButtonAnalytics(`${container} - ${position} Arrow`, ButtonClickAnalyticsType.BROWSE);
    }
};

export const slickClasses = {
    slide: "slick-slide",
    list: "slick-list",
    arrow: "slick-arrow",
    next: "slick-next",
    prev: "slick-prev",
    disabled: "slick-disabled",
    track: "slick-track",
    regular: "regular",
    oneSlide: "one-slide",
};

const StyledLink = styled("a", {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "fullScreenWidth";
    }
})(({ theme, disabled, fullScreenWidth }: { theme?: any; disabled: boolean; fullScreenWidth?: boolean; } & React.AnchorHTMLAttributes<HTMLAnchorElement>) => ({
    display: "block",
    width: "fit-content",
    [`&`]: {
        color: fullScreenWidth ? theme.kaltura.palette.tone1 : (disabled ? theme.kaltura.palette.tone4 : theme.kaltura.palette.tone3),
        "&:hover": {
            color: fullScreenWidth ? theme.kaltura.palette.tone1 : (disabled ? theme.kaltura.palette.tone4 : theme.kaltura.palette.tone6),
        },
        "svg": {
            width: theme.typography.pxToRem(36),
            height: theme.typography.pxToRem(36),
            [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
                width: theme.typography.pxToRem(48),
                height: theme.typography.pxToRem(48),
            },
            ...(fullScreenWidth && {
                "&:focus": {
                    outline: 0
                }
            })
        }
    }
}));

const StyledContainer = styled("div")(({ theme }: { theme?: any }) => ({
    /* override carousel style to match design */
    position: "relative",
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        paddingRight: theme.spacing(4),
    },
}));

const StyledSliderContainer = styled(Box)(({ theme }: { theme?: any }) => ({
    "&:hover": {
        [`.${slickClasses.arrow}`]: {
            opacity: 1,
            zIndex: 2
        },
    },
}));

const StyledSlider = styled(Slider, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "fullScreenWidth" && propName !== "useSpacing" && propName !== "imageHeight";
    }
})(({ theme, fullScreenWidth, useSpacing, imageHeight }: { theme?: any; fullScreenWidth?: boolean; useSpacing?: boolean; imageHeight?: number }) => [
    {
        // styles applied to root element
        // style applied to root element regular style
        [`&.${slickClasses.regular}`]: {
            whiteSpace: "nowrap",
            overflowX: "auto",
            msOverflowX: "auto",
            scrollbarWidth: "none",
            [StyledContainer as any]: {
                display: "inline-block",
                verticalAlign: "top",
            },
        },
        // desktops will have a scroll bar, and are less relevant in these sizes
        ["max-device-size: " + (theme.breakpoints.values.md - 0.05)]: {
            overflowX: "hidden",
            msOverflowX: "hidden",
        },
        // styles applied to root element on regular
        [`&.${slickClasses.regular}::-webkit-scrollbar`]: {
            display: "none",
        },
        // styles applied to slide
        [`.${slickClasses.slide}`]: {
            width: 276,
        },
        // styles applied to slide list
        [`.${slickClasses.list}`]: {
            marginRight: -16,
        },
        // styles applied to arrow
        [`.${slickClasses.arrow}`]: {
            zIndex: 10,
            height: "auto",
            ...(imageHeight !== 0 && {
                top: imageHeight / 2,
            }),
        },
        // styles applied to next arrow
        [`.${slickClasses.next}`]: {
            right: fullScreenWidth ? (useSpacing ? theme.spacing(5) : theme.spacing(3)) : -20,
        },
        // styles applied to prev arrow
        [`.${slickClasses.prev}`]: {
            left: fullScreenWidth ? 0 : -36,
        },
        // styles applied to track when one slide mode
        [`.${slickClasses.oneSlide} .${slickClasses.track}`]: {
            marginLeft: 0,
        },
        // styles applied to arrow (hide third party arrow)
        [`.${slickClasses.arrow}:before`]: {
            content: "none",
        },
        [theme.breakpoints.down("md")]: {
            // styles applied to root element
            [`&.${slickClasses.regular}`]: {
                marginRight: 0
            },
        },
        [theme.breakpoints.up("lg")]: {
            // styles applied to slide list
            [`.${slickClasses.list}`]: {
                marginRight: -30,
            },
            // styles applied to next arrow
            [`.${slickClasses.next}`]: {
                right: fullScreenWidth ? (useSpacing ? theme.spacing(5) : theme.spacing(3)) : -20,
            },
            // styles applied to prev arrow
            [`.${slickClasses.prev}`]: {
                left: fullScreenWidth ? 0 : -48,
            },
        },
    },
    fullScreenWidth && {
        overflow: "hidden",
        [`.${slickClasses.arrow}`]: {
            opacity: 0
        },
        [`.${slickClasses.track}`]: {
            left: useSpacing ? theme.spacing(2) : theme.spacing(0)
        }
    },
    !fullScreenWidth && systemWidth({ theme }),
]);

const StyledArrow = styled.div();

enum Side {
    left = 'left',
    right = 'right'
}

const StyledGradient = styled(Box, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "height" && propName !== "side";
    }
})(
    ({ theme, height, side }: { theme?: any; height: number; side: string}) => ({
        width: 100,
        height: height,
        backgroundImage: `linear-gradient(${side === Side.left ? "90deg" : "270deg" }, ${alpha(theme.kaltura.palette.surfaces.background, 0.8)} 0%, ${alpha(theme.kaltura.palette.surfaces.background, 0)} 100%)`,
        position: "absolute",
        top: 0,
        zIndex: 1,
        ...(side === Side.left && {
            left: 0
        }),
        ...(side === Side.right && {
            right: 0
        })
    })
);

const CustomArrow: React.FC<ArrowProps> = ({
    className,
    icon,
    iconClassName,
    onClick,
    style,
    disabled = false,
    ariaLabel,
    position,
    container,
    fullScreenWidth,
    handleKeyDown,
}) => {
    icon = React.cloneElement(icon, { className: iconClassName });

    const sendButtonAnalytics = useButtonAnalytics();

    const handleArrowClick = (e: React.FormEvent) => {
        preventRedirect(e, sendButtonAnalytics, position, container);
        onClick?.();
    }

    return (
        <StyledArrow className={className} style={style} onKeyDown={handleKeyDown} onClick={handleArrowClick} role="button">
            <StyledLink
                disabled={disabled}
                href={""}
                aria-label={ariaLabel || undefined}
                fullScreenWidth={fullScreenWidth}
            >
                {icon}
            </StyledLink>
        </StyledArrow>
    );
};

/**
 * Generic component for carousel. The component handle carousels of 2-4 cards.
 */
export const Carousel = <ItemT, PropsT>({
    items,
    currentCardsNumberInSlides = 4,
    mdCardsNumberInSlides = Math.min(currentCardsNumberInSlides, 3),
    minPossibleCardsNumberInSlides = currentCardsNumberInSlides,
    className,
    itemProps,
    itemComponent: ItemComponent,
    activeModal = "",
    setActiveModal,
    container,
    fullScreenWidth = false,
    ...rest
}: Props<ItemT, PropsT>) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isSlidingWithTab, setIsSlidingWithTab] = useState(false);
    const classes = useUtilityClasses(rest);
    const numberOfSlides = items.length - (currentCardsNumberInSlides - 1);
    const prevDisabled = currentSlideIndex === 0;
    const theme = useTheme();
    const [themeBreakpointsValues, setThemeBreakpointsValues] = useState(theme.breakpoints.values);
    const [sliderKey, setSliderKey] = useState(0);
    const [useSpacing, setUseSpacing] = useState<boolean>(true);
    const [tabIndexArray, setTabIndexArray] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);


    /**
     * Updates the tab index array to manage focusable elements within a carousel.
     * 
     * This function calculates which items should be focusable (tab index of 0) based on the current slide index.
     * @param {number} slideIndex - The index of the currently active or selected slide in the carousel.
     */
    const handleTabIndexChange = (slideIndex: number) => {
        // Initialize an array for tab indices with all elements set to -1 (not focusable)
        const initialTabIndexArray = Array(items.length).fill(-1);
        // Calculate the start index of focusable items based on the active slide index and number of items per slide
        let startIndex = slideIndex * currentCardsNumberInSlides;
         // Determine the end index for focusable items, ensuring it does not exceed the total number of items
        const endIndex = Math.min(startIndex + currentCardsNumberInSlides, items.length);
        // If the end index reaches the last item, adjust the start index to include exactly 'currentCardsNumberInSlides' items
        if(endIndex === items.length) {
            startIndex = items.length - currentCardsNumberInSlides
        }

        for (let i = startIndex; i < endIndex; i++) {
            // Set the tab index of each item within the current slide to 0 (focusable)
            initialTabIndexArray[i] = 0;
        }
         // Update the state with the new array of tab indices
        setTabIndexArray(initialTabIndexArray);
    }

    useEffect(() => {
        handleTabIndexChange(0);
    }, [])
    
    // remount Slider when theme breakpoints values update
    // else it crashes when the values in its state
    // are different from the new values passed to it
    useEffect(() => {
        setThemeBreakpointsValues(theme.breakpoints.values);
        setSliderKey(key => key + 1);
    }, [theme.breakpoints.values]);

    /*
     * The behavior of the "next slide" arrow button is different between
     * navigating the items with the arrow buttons and navigating the items with "tab".
     *
     * When navigating with the arrow buttons, the "next slide" button
     * should be disabled when reaching the last __page__.
     *
     * When navigating with "tab", the "next slide" button
     * should be disabled when reaching the last __item__.
     */
    const reachedEndOfSlides = isSlidingWithTab
        ? currentSlideIndex === items.length - 1
        : currentSlideIndex >= numberOfSlides - 1;

    const beforeSlideChange = (oldIndex: number, newIndex: number) => {
        setCurrentSlideIndex(newIndex);
        setIsSlidingWithTab(false);
    };

    const handleItemFocus = (index: number) => {
        setCurrentSlideIndex(index);
        setIsSlidingWithTab(true);
    };

    const handleSliding = (index: number) => {
        setUseSpacing(false)
    }

    const handleKeyDownRight = (e) => {
        if(e.key === "Enter") {
            if (!reachedEndOfSlides) {
                handleTabIndexChange(currentSlide + 1);
                setCurrentSlide(currentSlide + 1);
            }
        }
    }

    const handleKeyDownLeft = (e) => {
        if(e.key === "Enter") {
            if (!prevDisabled) {
                handleTabIndexChange(currentSlide - 1);
                setCurrentSlide(currentSlide - 1);
            }
        }
    }

    const settings: Settings = {
        slidesToShow: currentCardsNumberInSlides,
        slidesToScroll: currentCardsNumberInSlides,
        infinite: false,
        nextArrow: (
            <CustomArrow
                iconClassName={classes.arrow}
                disabled={reachedEndOfSlides}
                icon={<ChevronRight24Icon />}
                ariaLabel={translate("next slide arrow")}
                position={"Right"}
                container={container}
                fullScreenWidth={fullScreenWidth}
                handleKeyDown={handleKeyDownRight}
            />
        ),
        prevArrow: (
            <CustomArrow
                iconClassName={classes.arrow}
                disabled={prevDisabled}
                icon={<ChevronLeft24Icon />}
                ariaLabel={translate("previous slide arrow")}
                position={"Left"}
                container={container}
                fullScreenWidth={fullScreenWidth}
                handleKeyDown={handleKeyDownLeft}
            />
        ),
        beforeChange: beforeSlideChange,
        responsive: [
            {
                breakpoint: themeBreakpointsValues.md,
                settings: "unslick",
            },
            {
                breakpoint: themeBreakpointsValues.lg,
                settings: {
                    slidesToShow: mdCardsNumberInSlides,
                    slidesToScroll: mdCardsNumberInSlides,
                }
            }
        ],
        afterChange: (index) => handleSliding(index)
    };

    const onThumbnailResize = (height: number) => {
        setImageHeight(height);
    };

    const [imageHeight, setImageHeight] = useState(0);
    
    return (
        <StyledSliderContainer>
            {fullScreenWidth && numberOfSlides > 0 && !useSpacing && <StyledGradient height={imageHeight} side={Side.left} />}
            <StyledSlider
                className={clsx(className, classes.root, {
                    [slickClasses.oneSlide]:
                    items.length < currentCardsNumberInSlides,
                })}
                key={sliderKey}
                fullScreenWidth={fullScreenWidth}
                useSpacing={useSpacing}
                imageHeight={imageHeight}
                {...settings}
            >
                {items.map((item, index) => {
                    return (
                        <StyledContainer
                            key={index}
                            className={classes.item}
                            onFocus={() => handleItemFocus(index)}
                            
                        >
                            <ItemComponent
                                item={item}
                                tabIndex={tabIndexArray[index]}
                                currentCardsNumberInSlides={currentCardsNumberInSlides}
                                minPossibleCardsNumberInSlides={
                                    minPossibleCardsNumberInSlides
                                }
                                activeModal={activeModal}
                                setActiveModal={setActiveModal}
                                onThumbnailResize={onThumbnailResize}
                                {...(typeof itemProps === "function" ? (itemProps as (item: ItemT, index: number) => PropsT)(item, index) : itemProps)}
                            />
                        </StyledContainer>
                    )
                })}
            </StyledSlider>
            {fullScreenWidth && numberOfSlides > 0 && <StyledGradient height={imageHeight} side={Side.right} />}
        </StyledSliderContainer>
    );
};
