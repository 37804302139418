// Data stubs for Webcast stories and tests
// tslint:disable:max-line-length
import { KmsTypePresenter, WebcastEntry } from "@mediaspace/shared/types";

export const presenters: KmsTypePresenter[] = [
    {
        id: "5cb70f7668507",
        name: "Typical Kalturian",
        title: "Sus-worker",
        bio: "",
        link: "",
        image: "https://www.kaltura.com/api_v3/service/thumbasset/action/serve?format=2&clientTag=KMS+5.85.17%2C+build+0&apiVersion=14.19.0&thumbAssetId=0_ebghwooy&thumbParams%5BobjectType%5D=KalturaThumbParams&thumbParams%5BcropType%5D=3&thumbParams%5Bwidth%5D=42&thumbParams%5Bheight%5D=42&partnerId=1974541&kalsig=e78b2912177c593860b4eb8b3d79710e&ks=djJ8MTk3NDU0MXyJRPnNruRsyUyuH-ljHqB0Jr10m2ikRA1f9aiVrplrrx5bepY7YcXlqP5SgQkjQF1S5yT23z31esxNhx3RaMXq1rG9Vow-ClYOANVFiZXiXoSH8FzcrIWdrYXOhM9obV55x71v0JoQPeecskKopDhp",
    },
    {
        id: "5cb70f88a56b3",
        name: "John Bruce",
        title: "Project Manager",
        bio: "",
        link: "",
        image: "https://www.kaltura.com/api_v3/service/thumbasset/action/serve?format=2&clientTag=KMS+5.86.06%2C+build+0&apiVersion=14.19.0&thumbAssetId=1_t1vgni0i&thumbParams%5BobjectType%5D=KalturaThumbParams&thumbParams%5BcropType%5D=3&thumbParams%5Bwidth%5D=42&thumbParams%5Bheight%5D=42&partnerId=2547452&kalsig=9b6eb1a0ce2d80949b6d0901381434a0&ks=djJ8MjU0NzQ1MnyyaZnuyF63R7DM-RSljvSM3air-NqdAnuwMqMnLwsXh69iOXPOIinAg0Jtja8_TWC-1xLNwFobMV2Ixv36pc4Ifp4Ts1q6Y6bUJsMuVjYo8V8qLuDAIrZfKd9im16knG-3oWCEsi3rjJYSLMYToHb8",
    },
    {
        id: "5cb70f88a56b2",
        name: "Guesty",
        title: "Anonymous",
        bio: "",
        link: "",
        image: "https://www.kaltura.com/api_v3/service/thumbasset/action/serve?format=2&clientTag=KMS+5.85.17%2C+build+0&apiVersion=14.19.0&thumbAssetId=0_puy3xeos&thumbParams%5BobjectType%5D=KalturaThumbParams&thumbParams%5BcropType%5D=3&thumbParams%5Bwidth%5D=42&thumbParams%5Bheight%5D=42&partnerId=1974541&kalsig=219de9a13134cd3e9b80091470b5fa10&ks=djJ8MTk3NDU0MXyJRPnNruRsyUyuH-ljHqB0Jr10m2ikRA1f9aiVrplrrx5bepY7YcXlqP5SgQkjQF1S5yT23z31esxNhx3RaMXq1rG9Vow-ClYOANVFiZXiXoSH8FzcrIWdrYXOhM9obV55x71v0JoQPeecskKopDhp",
    },
    {
        id: "5cb70f7668508",
        name: "Frank Boosman",
        title: "Business developer, product marketer, project manager",
        bio: "",
        link: "",
        image: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/1_5pdtky4l/width/100/height/100/type/3",
    },
    {
        id: "5cb70f88a56b9",
        name: "Don Drake",
        title: "Sr. Solutions Architect, DBS",
        bio: "",
        link: "https://www.linkedin.com/in/don-drake-3631?trk=org-employees_profile-result-card_result-card_full-click",
    },
    {
        id: "5cb70f88a56b7",
        name: "Ted Collins",
        title: "CTO at Vitac, the world leader in Closed Captioning services for broadcast and online media",
        bio: "",
        link: "",
        image: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/1_5pdtky4l/width/100/height/100/type/3",
    },
    {
        id: "7368696d695f7461766f7269",
        name: "Shimi Tavori",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua., Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        link: "/profile/eyJpdiI6InBoTnFtdG9ZOWZEWGs2eE9zdlRMNlE9PSIsInZhbHVlIjoiMjFibktLWjNDXC9VZFpmTmY1N28wWXAzV2pveWk5aVZmVGVGck16U2p6SUU9IiwibWFjIjoiZWJhZmJiYmNhM2M3NjlkZTFjODdmY2M4NjdkZGEwOTM0ZTliNmQzOTk4ZTRjMTM1YjRlNjBhMjYxYWZhMWYwMSJ9"
    }
];

export const generateLiveEntry = (
    entryId: string,
    presentersCount = 2,
    startTime = 1556360100
): WebcastEntry => {
    const entry: any = {};
    entry.types = ["live"];
    entry.id = entryId;
    entry.name = "Mastering Digital Marketing Strategies: From Content Creation to Lead Conversion";
    entry.description =
        "Register today for an executive webcast with CIO, Mark Sunday, product management, and customer executives as they introduce Documents Cloud Service and discuss the business use cases for cloud content sharing and collaboration. But the following sentence will be truncated.";
    entry.tags = "tag with multiple words,tag2";
    entry.thumbnailUrl =
        "https://cfvod.kaltura.com/p/976461/sp/97646100/thumbnail/entry_id/1_u4lkoi3u/version/100011";
    entry.createdAt = 1556360593;
    entry.lastBroadcast = Date.now() / 1000;
    entry.duration = 0;
    entry.stats = {};
    entry.presenters = presenters.slice(0, presentersCount);
    entry.schedulingData = {
        start: {
            timestamp: startTime,
            timeZoneName: "Asia/Jerusalem",
            timeZoneOffset: 10800,
        },
        end: {
            timestamp: startTime + 3600,
            timeZoneName: "Asia/Jerusalem",
            timeZoneOffset: 10800,
        },
    };

    return entry;
};
export const liveEntry: WebcastEntry = generateLiveEntry("1_u4lkoi3u");

export const generateUpcomingEntry = (
    entryId: string,
    presentersCount = 2,
    startTime = 1559231100
): WebcastEntry => {
    const entry: any = {};
    entry.types = ["upcoming"];
    entry.id = entryId;
    entry.name =
        "Real-Time Talent Insights: enhance your organization with amazing analytics powers";
    entry.description =
        "See how Deloitte and Oracle work together to bring you next-generation workforce analytics that will quickly help your organization";
    entry.tags = "some,tag";
    entry.thumbnailUrl =
        "https://cfvod.kaltura.com/p/976461/sp/97646100/thumbnail/entry_id/1_f41z4daj/version/100032";
    entry.createdAt = 1555947291;
    entry.duration = 0;
    entry.stats = {
        plays: 269,
        votes: 120,
        comments: 5,
    };

    entry.presenters = presenters.slice(0, presentersCount);
    entry.schedulingData = {
        start: {
            timestamp: startTime,
            timeZoneName: "America/New_York",
            timeZoneOffset: 10800,
        },
        end: {
            timestamp: startTime + 3600,
            timeZoneName: "America/New_York",
            timeZoneOffset: 10800,
        },
    };

    return entry;
};
export const upcomingEntry: WebcastEntry = generateUpcomingEntry("1_f41z4daj");

export const generateRecordedEntry = (
    entryId: string,
    presentersCount = 2,
    startTime = 1556104500
): WebcastEntry => {
    const entry: any = {};
    entry.types = ["recorded"];
    entry.id = entryId;
    entry.name = "Introducing Mobile Cloud";
    entry.description =
        "<div><div>Some description with <b>tags</b><br>and multiline...</div></div>";
    entry.tags = "tag1tagg,tag2,tag333";
    entry.thumbnailUrl =
        "https://cfvod.kaltura.com/p/976461/sp/97646100/thumbnail/entry_id/1_gbuwisjb/version/100031";
    entry.createdAt = 1556105569;
    entry.lastBroadcast = 1556111426;
    entry.duration = 2697;
    entry.description =
        "Join Kaltura Co-founder, President & General Manager, Enterprise & Learning Dr. Michal Tsur and Head of Product Renan Gutman for an exciting look at future video developments. In this session";
    entry.stats = {
        plays: 269,
        votes: 120,
        comments: 5,
    };
    entry.presenters = presenters.slice(0, presentersCount);
    entry.schedulingData = {
        start: {
            timestamp: startTime,
            timeZoneName: "Asia/Jerusalem",
            timeZoneOffset: 10800,
        },
        end: {
            timestamp: startTime + 3600,
            timeZoneName: "Asia/Jerusalem",
            timeZoneOffset: 10800,
        },
    };
    return entry;
};
export const recordedEntry: WebcastEntry = generateRecordedEntry("1_gbuwisjb");

export const playerConfig = {
    provider: {
        partnerId: 976461,
        uiConfId: 44208271,
        ks: "YzI1OTY2ODQ0MGUxZGJjNmJiMGQyYjE0OTliZmYzMDZhNWZiMDI2MHw5NzY0NjE7OTc2NDYxOzI1NTkyMjI3Mzk7MDsxNTU5MjIyNzM5Ljk2MzY7dGVzdDtkaXNhYmxlZW50aXRsZW1lbnQsZW5hYmxlY2F0ZWdvcnltb2RlcmF0aW9uOzs=",
    },
};

export const getPresenters = (n: number): KmsTypePresenter[] => {

    if (n > presenters.length) {
        throw new RangeError("getRandom: more elements taken than available");
    }

    return presenters.filter((_, index) => {
        index < n;
    });
};
