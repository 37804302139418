import {
    Button,
    Checkbox,
    MenuItem,
    ModalActions,
    ModalContent,
    ModalTitle,
    Typography
} from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import { StyledCheckboxLabel, StyledCheckboxWrap, StyledContainer, StyledModal } from "./styled-components";
import { ChangeEvent, SyntheticEvent, useState } from "react";
import { UserSelection, Collaborator, HtmlReactParser, useToastsContext } from "@mediaspace/shared/ui";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface SetOwnerResponse {
    /**
     * true if owner was set for **all** entries successfully, false otherwise
     */
    success: boolean;

    /**
     * list of entries where new owner could not be set
     */
    errorEntries?: { id: string, name: string }[];

}

export interface ChangeOwnerProps {

    setOwner: (userId: string, keepEditPermission: boolean) =>  Promise<SetOwnerResponse>;

    entryIds: string[];

    /**
     * a callback to the wrapper component indicating all is done
     */
    onOwnerSet?: () => void;

    /**
     * KMS action to call when searching for users.
     * the search term will be appended on the url.
     * expected response in the form of {screenName: string, id: string, thumbnailUrl?: string}[]
     */
    usersSearchUrl: string;

    analyticsEventNameBase: string;

    /**
     * close main menu
     */
    onCloseMenu: () => void;
}

export function ChangeOwner({ setOwner, entryIds, onOwnerSet, usersSearchUrl, analyticsEventNameBase, onCloseMenu }: ChangeOwnerProps) {
    const [modalType, setModalType] = useState<"base"|"confirm">(null);
    const [selectedUser, setSelectedUser] = useState<Collaborator>();
    const [keepPermissions, setKeepPermissions] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const { showToast } = useToastsContext();
    const sendButtonAnalytics = useButtonAnalytics();

    const showOwnershipModal = () => {
        setModalType("base");
        sendButtonAnalytics(`${analyticsEventNameBase} - Transfer ownership selected`, ButtonClickAnalyticsType.CHOOSE);
    };

    const handleCancel = () => {
        const txt = modalType === "base" ? "Transfer ownership" : "Transfer ownership approval";
        setModalType(null);
        sendButtonAnalytics(`${analyticsEventNameBase} - ${txt} canceled`, ButtonClickAnalyticsType.CHOOSE);
        onCloseMenu();
    };

    const handleKeepPermissionsChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setKeepPermissions(e.target.checked);
        const chk = e.target.checked ? "checked" : "unchecked";
        sendButtonAnalytics(`${analyticsEventNameBase} - Transfer ownership set collab - ${chk}`, ButtonClickAnalyticsType.CHOOSE);
    }

    const handleSubmit = () => {
        if (modalType === "base") {
            setModalType("confirm");
            sendButtonAnalytics(`${analyticsEventNameBase} - Transfer ownership saved`, ButtonClickAnalyticsType.CHOOSE);
            return;
        }
        setSubmitted(true);
        sendButtonAnalytics(`${analyticsEventNameBase} - Transfer ownership approval - saved`, ButtonClickAnalyticsType.SAVE);

        setOwner(selectedUser.id, keepPermissions)
            .then(
                (response) => {
                    if (response.success) {
                        showToast({ severity: "success", message: translate("Media successfully updated") });
                    }
                    else if (response.errorEntries) {
                        let msg = translate("Could not update the following media:");
                        msg += "<ul>";
                        msg += response.errorEntries.map((e) => `<li>${e.id}, ${e.name}</li>`).join("");
                        showToast({ severity: "error", message: HtmlReactParser(msg) as unknown as Element });
                    }
                    else {
                        showToast({ severity: "error", message: translate("Error updating media") });
                    }
                },
                (error) => {
                    showToast({ severity: "error", message: translate("Error updating media") });
                })
            .finally(() => {
                // notify
                onOwnerSet?.();
                // close modal
                setModalType(null);
                onCloseMenu();
            });
    };



    return (
        <>
            <MenuItem onClick={() => showOwnershipModal()}>
                {translate("Transfer ownership")}
            </MenuItem>

            <StyledModal classes={{ "paper": "kms-ds-set-ownership-modal" }} open={modalType !== null}>
                <ModalTitle>{translate("Transfer ownership")}</ModalTitle>
                <ModalContent>
                    {modalType === "base" && (<StyledContainer>
                        <Typography variant={"body2"}>
                            {translate("By changing ownership, you will no longer be able to perform any actions on the media or be able to see it in your My Media, unless you are a collaborator on the entry.")}
                        </Typography>
                        <UserSelection
                            multiple={false}
                            placeholder={translate("Search collaborators")}
                            url={usersSearchUrl}
                            onChange={(event: SyntheticEvent<Element, Event>, value: Collaborator) => {
                                setSelectedUser(value);
                            }}
                        />
                        <StyledCheckboxWrap>
                            <Checkbox id={"keepPermissions"} onChange={handleKeepPermissionsChanged} />
                            <StyledCheckboxLabel text={translate("Make myself a collaborator")} htmlFor={"keepPermissions"} />
                        </StyledCheckboxWrap>
                    </StyledContainer>)}
                    {modalType === "confirm" && (
                        <Typography variant={"body1"} textAlign={"center"}>
                            <p>{translate("Are you sure you want to transfer ownership for %1 item(s)?", [entryIds.length])}</p>
                            <p>{translate("Note: You will no longer be able to perform any actions on the media or be able to see it in your My Media, unless you are a collaborator on the entry.")}</p>
                        </Typography>
                    )}

                </ModalContent>
                <ModalActions>
                    <Button variant={"borderless"} onClick={() => handleCancel()}>{translate("Cancel")}</Button>
                    <Button onClick={() => handleSubmit()} disabled={!selectedUser || submitted}>
                        {modalType == "base" && translate("Select")}
                        {modalType == "confirm" && translate("Transfer ownership")}
                    </Button>
                </ModalActions>
            </StyledModal>
        </>
    );
}

export default ChangeOwner;
