/**
 *    Fetch data, compatible with Suspense.
 *    How it works:
 *    - Start the request by calling fetchData() outside the suspended component, e.g.
 *      `const getEntries = fetchData("/entries");`
 *      It will return a callback that could be used to retrieve the results of the requests.
 *    - Use the callback in any place of the suspended component to get the data, e.g.
 *      `const entries = getEntries();`
 *    - While the data is loading, it will throw a special type of error for Suspense to catch.
 *      This way Suspense will understand that the data is not ready yet.
 *    - When the data is ready, the callback will return the data itself (not a promise!) that could be used as usual in the component.
 */
export function fetchData(url: string, options = {}) {
    let status = "pending";
    let result;

    const mockData = getMockData(url, {}, options["body"]);

    const dataPromise = mockData ? returnMockData(mockData) : fetch(url, options)
        .then((response: Response) => {
            if (!response.ok) {
                status = "rejected";
                result = new Error("server error");
            }
            // use response.text() + eval() instead of response.json() to accommodate
            // components, that are invalid json
            return response.text();
        })
        // Fetch request has gone well
        .then((data) => eval("(" + data + ")"));

    const fetching = dataPromise
        .then((data: unknown) => {
            status = "fulfilled";
            result = data;
        })
        // Fetch request has failed
        .catch((error: unknown) => {
            status = "rejected";
            result = error;
        });

    return () => {
        if (status === "pending") {
            throw fetching; // Suspend(A way to tell React data is still fetching)
        } else if (status === "rejected") {
            throw result; // Result is an error
        } else if (status === "fulfilled") {
            return result; // Result is a fulfilled promise
        }
    };
}

/**
 * add nonce to fetch requests options
 */
export const getKMSHeader = () => {
    // nonce from globals
    const nonce = document.head
        .querySelector("[name=xsrf-ajax-nonce]")
        ?.getAttribute("content");

    // add nonce to the request headers
    const options = { headers: { "X-Kms-Nonce": nonce } };
    return options;
};

/**
 * reference used to abort previous requests
 */
let lastAbortableRequestCtrl: AbortController;
/**
 * fetch data from kms - compatible with kms nonce security and Suspense.
 */
export function fetchKmsData(
    url: string,
    data: any = undefined,
    abortable = false
) {
    // abort previous request if any valid for abortion.
    // only an abortable request may abort other requests.
    if (abortable && lastAbortableRequestCtrl) {
        lastAbortableRequestCtrl.abort();
        lastAbortableRequestCtrl = undefined;
    }

    let options: any = getKMSHeader();

    // is this post
    if (data) {
        // add post headers and data
        options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
            ...options,
        };
        if (abortable) {
            const ctrl = new AbortController();
            options["signal"] = ctrl.signal;
            lastAbortableRequestCtrl = ctrl;
        }
    }

    return fetchData(url, options);
}

/**
 * get data using fetch - no Suspense support.
 */
async function getData(url: string, options = {}) {
    const mockData = getMockData(url);
    if (mockData) {
        return returnMockData(mockData);
    }

    const response = await fetch(url, { method: "GET", ...options });

    // validate response
    if (!response.ok) {
        throw new Error("fetch error");
    }

    // use response.text() + eval() instead of response.json() to accomodate
    // components, that are invalid json
    const responsetext = await response.text();
    const result = eval("(" + responsetext + ")");

    return result;
}

/**
 * get data from kms - compatible with kms nonce security. no Suspense support.
 */
export async function getKmsData(url: string) {
    const options = getKMSHeader();
    return getData(url, options);
}

/**
 * post data using fetch - no Suspense support.
 */
async function postData(url: string, options = {}, data = {}) {
    const mockData = getMockData(url, data);
    if (mockData) {
        return returnMockData(mockData);
    }

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
        ...options,
    });

    // validate response
    if (!response.ok) {
        throw new Error("fetch error");
    }

    // use response.text() + eval() instead of response.json() to accomodate
    // components, that are invalid json
    const responsetext = await response.text();
    const result = eval("(" + responsetext + ")");

    return result;
}

/**
 * post data to kms - compatible with kms nonce security. no Suspense support.
 */
export async function postKmsData(url: string, data = {}) {
    const options = getKMSHeader();
    return postData(url, options, data);
}

export const getMockData = (
    url: string,
    data: any = {},
    postParams: any = undefined
) => {
    // outside of storybook, do nothing
    if (!(window as any).storybookMockData) {
        return null;
    }

    const key =
        url +
        (typeof postParams === "string"
            ? postParams
            : postParams
            ? JSON.stringify(postParams)
            : "");

    // first, search with post params if we have them
    let mockData = (window as any).storybookMockData?.[key];
    // search without post params - it may have been indexed without them(most likely)
    if (!mockData && postParams) {
        mockData = (window as any).storybookMockData?.[url];
    }

    //added for backward compatibility with previous solution - if we dont have the full url with query params, search without them
    if (!mockData) {
        const urlWithNoQuery = url.split("?")[0];
        mockData = (window as any).storybookMockData?.[urlWithNoQuery];
    }

    return typeof mockData === "function" ? mockData(data) : mockData;
};

const returnMockData = async (data: any) => {
    if (data instanceof Error) {
        throw data;
    }

    return data;
};
