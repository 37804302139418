import { cdnUrl, formatters, translate } from "@mediaspace/shared/utils";
import styled from "@emotion/styled";
import { alpha, Box, composeClasses } from "@mediaspace/shared/styled";
import { SimpleThumbnailClasses, getSimpleThumbnailClass } from "./simpleThumbnailClasses";
import clsx from "clsx";
import { useState } from "react";

export interface ThumbnailProps {
    classes?: Partial<SimpleThumbnailClasses>;
    thumbnailUrl: string;
    name: string;
    duration?: number;
    isLive?: boolean;
    roundCorners?: boolean;
}


const slots = {
    root: ["root"],
    image: ["image"],
    indicator: ["indicator"],
    live: ["live"],
};

const useUtilityClasses = (styleProps: ThumbnailProps) => {
    const { classes } = styleProps;

    return composeClasses(slots, getSimpleThumbnailClass, classes);
};

const ThumbnailContainer = styled(Box, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "roundCorners";
    }
})<{roundCorners: boolean}>(
    ({ theme, roundCorners }) => ({
        minWidth: 144,
        minHeight: 84,
        width: "100%",
        height: "100%",
        borderRadius: roundCorners ? theme.kaltura.shape.roundness1 : 0,
        overflow: "hidden",
        position: "relative",
    })
);

const StyledImage = styled("img")(({ theme }: { theme?: any }) => ({
    width: "100%",
    height: "100%",
    verticalAlign: "middle"
}));

const Live = styled("div")(({ theme }: { theme?: any }) => ({
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: theme.kaltura.palette.danger.main,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderRadius: theme.kaltura.shape.roundness1
}));

const Duration = styled("div")(({ theme }: { theme?: any }) => ({
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: alpha(theme.palette.common.black, 0.6),
    color: theme.palette.common.white,
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderRadius: theme.kaltura.shape.roundness1
}));

/**
 * thumbnail with duration or live indication
 * used in media-page (related entries)
 * used in global-search (media-card)
 */
export const SimpleThumbnail = (props: ThumbnailProps) => {

    const {thumbnailUrl, name, duration, isLive = false, roundCorners = true} = props;
    const classes = useUtilityClasses( props);
    const [isErr, setIsErr] = useState(false);

    return (
        <ThumbnailContainer
            className={clsx(classes.root, isLive && classes.live)}
            roundCorners={roundCorners}
        >
            {isErr && (
                <StyledImage src={cdnUrl + "/media/small_thumbnail.svg"} alt={name} className={classes.image} onError={() => setIsErr(true)} />
            )}
            {!isErr && (
                <StyledImage src={thumbnailUrl} alt={name} className={classes.image} onError={() => setIsErr(true)} />
            )}
            {isLive && (
                <Live className={classes.indicator}>
                    {translate("LIVE")}
                </Live>
            )}
            {!isLive && !!duration && (
                <Duration className={classes.indicator}>
                    {formatters.formatSecondsToTime(duration)}
                </Duration>
            )}
        </ThumbnailContainer>
    )
}

