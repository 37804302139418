import { baseUrl, postKmsData } from "@mediaspace/shared/utils";
import { useToastsContext } from "@mediaspace/shared/ui";
import { PublishStatus, PublishSaveRequestData } from "@mediaspace/shared/types";
import { PrivateModal } from "./private-modal/PrivateModal";
import { UnlistedModal } from "./unlisted-modal/UnlistedModal";
import { PublishedModalContainer } from "./published-modal/PublishedModalContainer";

export interface ModalManagerProps {
	entryIds: string[];
	publishStatus: PublishStatus;
	targetStatus: PublishStatus;
	onSubmit: (result: boolean, targetStatus: PublishStatus) => void;
	onClose: () => void;
}

/**
 * Publish Menu Item selection Modal Manager - select the correct modal by publish state,
 * and communicate with KMS
 */
export function ModalManager(props: ModalManagerProps) {
	const { entryIds, publishStatus, targetStatus, onClose, onSubmit } = props;

	const { showToast } = useToastsContext();

	// submit save to kms
	const handleSubmit = async (data: PublishSaveRequestData) => {
		// send data to kms
		const result = await postKmsData(`${baseUrl}/publishmedia/media/save/`, data);

		if (result.success) {
			// update the menu
			onSubmit(true, data.state);
		} else {
			// close the menu
			onClose();
		}

		// show toast messages
		const severity = result.success ? "success" : "error";
		if (result.messages) {
			result.messages.map((message) => {
				showToast({ severity: severity, message: message });
			});
		}
	};

	switch (targetStatus) {
		case PublishStatus.UNLISTED:
			return (
				<UnlistedModal
					entryIds={entryIds}
					publishStatus={publishStatus}
					onSubmit={handleSubmit}
					onClose={onClose}
				/>
			);
			break;
		case PublishStatus.PRIVATE:
			return (
				<PrivateModal
					entryIds={entryIds}
					publishStatus={publishStatus}
					onSubmit={handleSubmit}
					onClose={onClose}
				/>
			);
			break;
		case PublishStatus.PUBLISHED:
			return (
				<PublishedModalContainer
					entryIds={entryIds}
					publishStatus={publishStatus}
					onSubmit={handleSubmit}
					onClose={onClose}
				/>
			);
			break;
		default:
			return null;
			break;
	}
}
