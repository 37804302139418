import { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalActions, ModalContent, ModalTitle, Typography } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import ReactHtmlParser from "@mediaspace/shared/ui/html-react-parser";
import { useMediaDrawer } from "@mediaspace/features/media-page/media-drawer/MediaDrawerContext";
import { styled } from "@mui/material";

const modalPaperClassName = "kms-ds-gen-ai-exit-confirmation-modal";
/**
 * Generate AI quiz exit confirmation
 */
export const ExitConfirmation = () => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { setOnCloseConfirmation, close: closeDrawer } = useMediaDrawer();
    const isConfirmed = useRef(false);

    useEffect(() => {
        const confirmHandler = () => {
            setShowConfirmationModal(!isConfirmed.current);
            return isConfirmed.current;
        };

        setOnCloseConfirmation(confirmHandler);
        return () => {
            setOnCloseConfirmation();
        };
    }, []);

    const exitGenerateAiQuiz = () => {
        isConfirmed.current = true;
        closeDrawer();
    };

    return (
        <StyledModal open={showConfirmationModal} classes={{ paper: modalPaperClassName }}>
            <ModalTitle>{translate("Exit quiz creation")}</ModalTitle>
            <ModalContent>
                <Typography variant={"body1"}>
                    {ReactHtmlParser(
                        translate(
                            "You are leaving the quiz creation process, the quiz will show up in your my-media."
                        )
                    )}
                </Typography>
            </ModalContent>
            <ModalActions>
                <Button size={"small"} variant={"borderless"} onClick={() => setShowConfirmationModal(false)}>
                    {translate("Continue")}
                </Button>
                <Button onClick={exitGenerateAiQuiz} color={"danger"}>
                    {translate("Exit quiz creation")}
                </Button>
            </ModalActions>
        </StyledModal>
    );
};

const StyledModal = styled(Modal)({
    [`.${modalPaperClassName}`]: {
        maxWidth: 584,
        textAlign: "center",
    },
});
