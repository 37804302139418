import styled from "@emotion/styled";
import { useContext } from "react";
import { KmsTypePublishCategory } from "@mediaspace/shared/types";
import { Link, Tooltip } from "@kaltura/ds-react-components";
import { CategoryLinkAnalyticsContext } from "@mediaspace/shared/entry";

interface CategoryLink extends KmsTypePublishCategory {
    url?: string;
    label?: string;
}
interface CategoryLinksProps {
    categories: CategoryLink[];
}

const StyledCategoryLinks = styled("div")(({ theme }: { theme?: any }) => ({}));

export const StyledCategoryLink = styled(Link)(({ theme }: { theme?: any }) => ({
    ...theme.typography.body2Highlight,
    color: theme.palette.text.primary,
    textDecoration: "none",
    marginRight: theme.spacing(0.5),
}));

/**
 * category links to show inside ExternalPublishedCategories and TitledCategoryLinks
 */
export function CategoryLinks({ categories }: CategoryLinksProps) {

    const { sendCategoryLinkAnalytics } = useContext(CategoryLinkAnalyticsContext);

    return (
        <StyledCategoryLinks>
            {categories.map((category, index) => (
                <Tooltip title={category.label}>
                    <StyledCategoryLink
                        className="kms-ds-publish-media-category-link"
                        href={category.url}
                        key={category.id}
                        onClick={() => category.url && sendCategoryLinkAnalytics()}
                    >
                        {`${category.name}${index < categories.length - 1 ? "," : ""}`}
                    </StyledCategoryLink>
                </Tooltip>
            ))}
        </StyledCategoryLinks>
    );
}
