import { forwardRef, ChangeEvent } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import {
    StyledInput
} from "@mediaspace/shared/ui/form/FormElementStyles";
import {
    Container,
    StyledFormLabel
} from "./CommonStyles";

export interface TextFieldProps {
    label: string;
    id: string;
    description?: string;
    placeholder?: string;
    defaultValue?: string;
    error?: FieldError;
    errorMessage?: string;
    required?: boolean;
    type?: string;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}


/**
 * text input field for edit entry form
 */
const TextField = forwardRef<any, TextFieldProps>((props: TextFieldProps, ref) => {
    const {
        label,
        id,
        placeholder,
        required,
        type = "text",
        description,
    } = props;

    const { register, formState: {errors} } = useFormContext();


    return (
        <Container>
            <StyledFormLabel htmlFor={id} required={required} text={label} description={description} />
            <div>
                <StyledInput
                    id={id}
                    error={!!errors[id]}
                    inputRef={ref}
                    type={type}
                    aria-invalid={errors[id] ? "true" : "false"}
                    placeholder={placeholder}
                    required={required}
                    {...register(id as 'dummy', { validate: val => !required || !!(val.trim()) })}
                />
            </div>
        </Container>
    );
});

export default TextField;
