import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container, StyledFormLabel } from "./CommonStyles";
import MTF from "@mediaspace/shared/ui/form/multi-text-field/MultiTextField";

export interface MultiTextFieldProps {
    label: string;
    id: string;
    description?: string;
    value?: string[];
}


/**
 * multi-text input field for edit entry form
 */
const MultiTextField = forwardRef<any, MultiTextFieldProps>((props: MultiTextFieldProps, ref) => {
    const {
        label,
        id,
        description,
        value
    } = props;

    const { control } = useFormContext();


    return (
        <Container>
            <StyledFormLabel
                htmlFor={id}
                text={label}
                description={description}
            />
            <Controller
                control={control}
                name={id as 'dummy'}
                defaultValue={value || []}
                render={({ field: { onChange, onBlur, value } }) => (
                    <MTF
                        id={id}
                        value={value}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                )}
            />
        </Container>
    );
});

export default MultiTextField;
