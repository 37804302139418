import styled from "@emotion/styled";
import {Theme} from "@mui/material";
import {Button} from "@kaltura/ds-react-components";

export const StyledPublishStatus = styled("div")(
    ({theme}: {theme: Theme}) => ({
        display: "flex",
        gap: theme.spacing(0.5),
        alignItems: "center"
    })
);

export const StyledButton = styled(Button)(({theme}) => ({
    padding: 0,
    height: "auto",
    "&:hover": {
        backgroundColor: "transparent",
        color: theme.kaltura.palette.primary.dark
    },
}));

export const StyledContentContainer = styled("div")(({theme}) => ({
    width: 300,
    maxHeight: 400
}));

export const Separator = styled("span")(({theme}) => ({
    width: theme.spacing(0.5),
    height: 0
}));
