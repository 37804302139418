import styled from "@emotion/styled";
import { KmsTypePublishExternalCategories } from "@mediaspace/shared/types";
import { translate } from "@mediaspace/shared/utils";
import { Typography } from "@kaltura/ds-react-components";
import { CategoryLinks } from "../category-links/CategoryLinks";
import { TitledCategoryLinks } from "../category-links/TitledCategoryLinks";
import { addExternalCategoryLabel } from "../../utils";

interface ExternalAppPublishedCategoriesProps {
    externalCategories: KmsTypePublishExternalCategories;
}

const StyledCategories = styled("div")(({ theme }: { theme?: any }) => ({
    marginBottom: theme.spacing(1),
    display: "flex",
}));

const StyledAppTitle = styled(Typography)(({ theme }: { theme?: any }) => ({
    color: theme.kaltura.palette.tone3,
    display: "block",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
}));

/**
 * Publish menu - External published categories for one external app
 */
function ExternalAppPublishedCategories({ externalCategories }: ExternalAppPublishedCategoriesProps) {
    // get the external categories
    const { categories = {}, applicationName = "" } = externalCategories;
    const { published = [], pending = [], rejected = [] } = categories;

    const publishedExternalCategories = addExternalCategoryLabel(published);
    const pendingExternalCategories = addExternalCategoryLabel(pending);
    const rejectedExternalCategories = addExternalCategoryLabel(rejected);

    const hasExternalCategories =
        publishedExternalCategories.length + pendingExternalCategories.length + rejectedExternalCategories.length > 0;

    return (
        hasExternalCategories && (
            <>
                <StyledAppTitle variant="body1Highlight">
                    {translate("Published in %1:", [applicationName])}
                </StyledAppTitle>

                {publishedExternalCategories.length > 0 && (
                    <StyledCategories>
                        <CategoryLinks categories={publishedExternalCategories} />
                    </StyledCategories>
                )}

                <TitledCategoryLinks
                    categories={pendingExternalCategories}
                    title={translate("Pending in:")}
                    color={"warning.main"}
                ></TitledCategoryLinks>

                <TitledCategoryLinks
                    categories={rejectedExternalCategories}
                    title={translate("Rejected in:")}
                    color={"error.main"}
                ></TitledCategoryLinks>
            </>
        )
    );
}

export interface ExternalPublishedCategoriesProps {
    externalCategories: KmsTypePublishExternalCategories[];
}

/**
 * Publish menu - External published categories for several external apps
 */
export function ExternalPublishedCategories({ externalCategories = [] }: ExternalPublishedCategoriesProps) {
    return (
        <>
            {externalCategories.map((externalAppCategories) => (
                <ExternalAppPublishedCategories
                    key={externalAppCategories.applicationName}
                    externalCategories={externalAppCategories}
                />
            ))}
        </>
    );
}