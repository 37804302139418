import {useState} from "react";
import {MediaCategoriesContainer} from "../media-categories/MediaCategoriesContainer";
import {Separator, StyledButton, StyledContentContainer} from "./styled-components";
import { MouseEvent } from "react";
import {useButtonAnalytics} from "@mediaspace/hooks";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import {NoLinkIndicatorProps} from "./NoLinkIndicator";
import {Popover} from "@kaltura/ds-react-components";

interface LinkIndicatorProps extends NoLinkIndicatorProps {
    entryId: string;
    showWarning: boolean;
}

/**
 * status indicator for cases where the indicator should act as a link
 */
const LinkIndicator = (props: LinkIndicatorProps) => {

    const {icon, label, entryId, showWarning = false} = props;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const sendButtonAnalytics = useButtonAnalytics();
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        sendButtonAnalytics("My-Media card - Publish indicator", ButtonClickAnalyticsType.EXPAND);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <StyledButton
                aria-describedby={id}
                onClick={handleClick}
                variant={"borderless"}
            >
                {icon}<Separator />{label}
            </StyledButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <StyledContentContainer>
                    <MediaCategoriesContainer
                        showExternal={true}
                        entryId={entryId}
                    />
                </StyledContentContainer>
            </Popover>
        </>
    );
}

export default LinkIndicator;
