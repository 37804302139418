import {
    Button,
    MenuItem,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    Typography
} from "@kaltura/ds-react-components";
import { baseUrl, postKmsData, translate, translatePlural } from "@mediaspace/shared/utils";
import { useState } from "react";
import { HtmlReactParser, useToastsContext } from "@mediaspace/shared/ui";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";
import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { KmsTypeComponent } from "@mediaspace/shared/types";
import { Delete24Icon } from "@kaltura/ds-react-icons";
import { BulkActionProps } from "./../BulkActionProps";


/**
 * TS representation of the server response to delete request
 */
interface DeleteResponse {
    /**
     * number of deleted media
     */
    deleted: number;

    /**
     * number of media that user is not allowed to delete if any
     */
    notAllowed?: number;

    /**
     *  error description if any
     */
    error?: string;
}

export interface BulkDeleteProps extends BulkActionProps {
    /**
     * show as button or as menu item
     * (set by server interface)
     */
    displayMode: "button" | "menu";

    /**
     * delete confirmation popup title
     */
    confirmationTitle: string;

    /**
     * delete confirmation modal content
     * (the message takes parameters, so we can't pass in the
     * processed, translated text like we do on media-page.
     * instead, we will take a component that will have a prop
     * for the number of media and will handle the translation inline)
     */
    confirmationMessage?: KmsTypeComponent

    confirmationButtonText: string;

    /**
     * override the default bulk delete KMS action
     */
    deleteActionUrl?: string;

    onDelete: (entryIds: string[]) => Promise<DeleteResponse>;
}

const StyledMessageText = styled(Typography)(
    ({theme}: {theme: Theme}) => ({
        color: theme.kaltura.palette.tone1,
        textAlign: "center",
    })
);

const StyledModal = styled(Modal)(
    ({ theme }) => ({
        "& .kms-ds-bulk-delete-modal": {
            [theme.breakpoints.up("sm")]: {
                width: 584,
            }
        }
    })
);

function BulkDelete({ displayMode, entryIds, reloadMedia, confirmationTitle,
                       confirmationMessage,
                       confirmationButtonText, onDelete, onCloseMenu }: BulkDeleteProps) {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [processing, setProcessing] = useState(false);

    const sendButtonAnalytics = useButtonAnalytics();
    const { showToast } = useToastsContext();

    const closeConfirmation = () => {
        setOpenConfirmation(false);
        sendButtonAnalytics("My-Media bulk actions - Close Delete modal", ButtonClickAnalyticsType.CLOSE);
        if (displayMode === "menu") {
            onCloseMenu();
        }
    };
    const handleClick = () => {
        setOpenConfirmation(true);
        sendButtonAnalytics("My-Media bulk actions - Open Delete modal", ButtonClickAnalyticsType.CHOOSE);
    }

    const handleDelete = async () => {
        setProcessing(true);
        sendButtonAnalytics("My-Media bulk actions - Delete executed", ButtonClickAnalyticsType.DELETE);

        onDelete(entryIds).then(
            response => {
                // check response content
                if (response.error) {
                    console.log("error deleting media: ", response.error);
                    if (response.error === "noAllowedMedia") {
                        showToast({severity: "error", message: translate("You are not allowed to delete selected media")});
                        return;
                    }
                    showToast({severity: "error", message: translate("Failed to delete media")});
                    return;
                }

                let text = translate("%1 media successfully deleted", [response.deleted]);
                if (response.notAllowed) {
                    text += " " + translatePlural("(You are not allowed to delete other %1 entry)", "(You are not allowed to delete other %1 entries)", [response.notAllowed]);
                }

                showToast({severity: "success", message: text});
            },
            error => {
                console.log("error deleting media: ", error);
                showToast({severity: "error", message: translate("Failed to delete media")});
            }
        ).finally(() => {
            setProcessing(false);
            setOpenConfirmation(false);
            reloadMedia();
        });
    };

    let text = translate('Are you sure you want to permanently delete this item from your media library? (ID: %1)<br />Note: This action is irreversible and deleted items are unrecoverable.' , [entryIds[0]]);
    if(entryIds.length > 1){
        text = translate('Are you sure you want to permanently delete %1 items from your media library?<br />Note: This action is irreversible and deleted items are unrecoverable.', [entryIds.length]);
    }

    const {component: ConfirmationMessageComponent, props: confirmationComponentProps} = confirmationMessage ?? {};

    return (
        <>
            {displayMode === "button" && (
                <Button variant={"borderless"} onClick={handleClick} startIcon={<Delete24Icon />}>{translate("Delete")}</Button>
            )}
            {displayMode === "menu" && (
                <MenuItem onClick={handleClick}>{translate("Delete")}</MenuItem>
            )}
            <StyledModal open={openConfirmation} onClose={closeConfirmation}
                   classes={{ "paper": "kms-ds-bulk-delete-modal" }} >
                <ModalTitle>{confirmationTitle}</ModalTitle>
                <ModalContent>
                    {!!ConfirmationMessageComponent && <ConfirmationMessageComponent {...confirmationComponentProps} entryIds={entryIds} />}
                    {!ConfirmationMessageComponent && <StyledMessageText variant={"body1"}>{HtmlReactParser(text)}</StyledMessageText>}
                </ModalContent>
                <ModalActions>
                    <Button
                        variant={"borderless"}
                        onClick={closeConfirmation}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        color="danger"
                        onClick={handleDelete}
                        loading={processing}
                        disabled={processing}
                    >
                        {confirmationButtonText}
                    </Button>
                </ModalActions>
            </StyledModal>
        </>
    );
}

const BulkDeleteContainer = ({ entryIds, deleteActionUrl = "/default/my-media/delete/id/", ...rest }: Omit<BulkDeleteProps, "onDelete">) => {
    const ids = entryIds.join(",");
    return (
        <BulkDelete
            onDelete={() => postKmsData(`${baseUrl}${deleteActionUrl}${ids}`)}
            entryIds={entryIds}
            {...rest}
        />
    );
}

export default BulkDeleteContainer;
