import styled from "@emotion/styled";
import { PublishStatus, PublishSaveRequestData } from "@mediaspace/shared/types";
import { Modal, ModalContent, modalClasses } from "@kaltura/ds-react-components";

export interface ModalProps {
    entryIds: string[];
    publishStatus: PublishStatus;
    onClose?: () => void;
    onSubmit?: (data: PublishSaveRequestData) => void;
}

export const StyledModal = styled(Modal)(({ theme }: { theme?: any }) => ({
    [`& .${modalClasses.paper}`]: {
        minWidth: 600,
    },
}));

export const StyledModalContent = styled(ModalContent)(({ theme }: { theme?: any }) => ({
    [`&`]: {
        marginBottom: theme.spacing(5),
    },
}));
