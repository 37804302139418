import { baseUrl, fetchKmsData, translate } from "@mediaspace/shared/utils";
import { Suspense, useMemo } from "react";
import { Box } from "@mediaspace/shared/styled";
import { SidebarContentsTitle } from "@kaltura/ds-react-layouts";
import { Bulletlist24Icon } from "@kaltura/ds-react-icons";
import { ErrorBoundary } from "@mediaspace/shared/ui";
import styled from "@emotion/styled";
import { SidebarContents, SidebarContentsSkeleton } from "./SidebarContents";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";

/*
 * Contents of the channel TV agenda sidebar
 */

export interface SidebarContentsContainerProps {
    channelId: number;
    currentEntryId?: string;
    minBreak: number;
    maxBreak: number;
}

export const SidebarContentsContainer = (props: SidebarContentsContainerProps) => {
    const { channelId } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const fetchEntries = useMemo(() => fetchKmsData(baseUrl + `/channeltv/get-items/${channelId}`), [channelId]);

    return (
        <StyledOuterContainer>
            <SidebarContentsTitle
                title={translate("Agenda")}
                icon={<Bulletlist24Icon />}
                onClose={() => sendButtonAnalytics("CnC - Close TV guide sidebar", ButtonClickAnalyticsType.CLOSE)}
            />

            <StyledInnerContainer>
                <ErrorBoundary>
                    <Suspense fallback={<SidebarContentsSkeleton />}>
                        <SidebarContents {...props} fetchEntries={fetchEntries} />
                    </Suspense>
                </ErrorBoundary>
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};

const StyledOuterContainer = styled(Box)({
    position: "absolute",
    inset: 0,
    display: "flex",
    flexDirection: "column",
});

const StyledInnerContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    minHeight: 0,
    padding: theme.spacing(2),
    overflow: "auto",
}));
