import styled from "@emotion/styled";
import React from "react";
import { Locale } from "@mediaspace/shared/types/Locale";
import { kmsCookie } from "@mediaspace/shared/utils";
import { Button, Menu, MenuItem } from "@kaltura/ds-react-components";
import { ChevronDown16Icon, Check16Icon } from "@kaltura/ds-react-icons";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import {useButtonAnalytics} from "@mediaspace/hooks";
import { translate } from "@mediaspace/shared/utils";

export interface LocaleMenuProps {
    locales: Locale[];
    onLocaleChange?: (locale: Locale) => void;
    currentLocale: Locale;
    disableScrollLock?: boolean;
}

const LocaleButton = styled(Button)(({ theme }: { theme?: any }) => {
    return {
        ...theme.kaltura.typography.buttonLabel2,
        backgroundColor: "initial",
        [`&:hover`]: {
            backgroundColor: "initial",
        },
        [`& .__private__-button-endIcon`]: {
            margin: 0,
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
          paddingLeft: theme.spacing(0.5), // - only on small screen
        },
    };
});

/**
 * Component to show locale selection
 */
export function LocaleMenu({
    locales,
    onLocaleChange,
    currentLocale,
    disableScrollLock = false,
}: LocaleMenuProps) {
    const loadNewLocale = (locale: Locale) => {
        if (onLocaleChange) {
            onLocaleChange(locale);
            return;
        }
        createLocaleCookie(locale.id);
        // update url's lang param value according to selected language
        const currentUrl = window.location.href;
        const updatedUrl = currentUrl.replace(/(lang=)[^&]+/, "$1" + locale.id);
        // redirect to updated url
        window.location.href = updatedUrl;
    };

    const createLocaleCookie = (localeId: string) => {
        const date = new Date();
        // set cookie for 10 years
        date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);

        const options = {
            expires: date.toUTCString(),
            path: "/",
        };

        kmsCookie("kms-locale", localeId, options);
    };

    const sendButtonAnalytics = useButtonAnalytics();

    const handleLocaleSelected = (localeId: string) => {
        const locale = locales.find((value) => value.id === localeId);
        // we are bound to find the value, because we got it from that list to start with.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        loadNewLocale(locale!);
        sendButtonAnalytics(`Language Selected - ${locale?.code}`, ButtonClickAnalyticsType.CHOOSE);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        sendButtonAnalytics("Language - Menu", ButtonClickAnalyticsType.MENU);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"kms-ds-locale-menu"}>
            <LocaleButton
                color={"translucent"}
                id="locale-button"
                aria-controls="locale-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                aria-label={translate("Language")}
                onClick={handleButtonClick}
                endIcon={<ChevronDown16Icon />}
            >
                {currentLocale.code.toUpperCase()}
            </LocaleButton>
            <Menu
                id="locale-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        width: "200px",
                    },
                }}
                aria-labelledby={"locale-button"}
                disableScrollLock={disableScrollLock}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {locales.map((locale) => {
                    return (
                        <MenuItem
                            key={locale.id}
                            value={locale.id}
                            onClick={() => handleLocaleSelected(locale.id)}
                            selected={currentLocale.id === locale.id}
                            aria-current={currentLocale.id === locale.id}
                            icon={
                                currentLocale.id === locale.id && (
                                    <Check16Icon />
                                )
                            }
                        >
                            {locale.code.toUpperCase()} &nbsp;{" "}
                            {locale.clientName}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
